import * as React from "react";
import {useState} from "react"
import OutlineButton from "../../../Generic/Button/Outline/Button";
import SecondaryButton from "../../../Generic/Button/Secondary/Button";
import TextButton from "../../../Generic/Button/Text/Button";
import NavComponent from "../../../Generic/NavBarComponent";
import Paragraph from "../../../Generic/Paragraph";
import TextButtonWithIcon from "../../../Generic/Button/Text/ButtonWithIcon";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import { ReactComponent as SearchIco } from "../../../../Assets/search.svg";
interface Props {
  content?: any;
}
const MobileMenu = ({ content }: Props) => {
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const [subMenu, setSubMenu] = useState([]);
  const [lastItem, setLastItem] = useState(null);
  const [query, setQuery] = useState("");
  const handleSubMenu = (submenulist, lastItem) => {
    setSubMenu(submenulist);
    setLastItem(lastItem);
    setIsOpenSubmenu(true);
  };
  const closeNavSuper = () => {
    setIsOpenSubmenu(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = `/search-results?query=${encodeURIComponent(query)}`
  };

  return (
    <div data-testid="mobilemenu" className="mt-4 absolute top-30 w-full left-0 z-10 pb-120 bg-neutral-10 border rounded shadow-lg mobileMenu">
      <div className="relative overflow-y-scroll mobileMenuWrapper">
        <div className="force-overflow">
          <ul>
            <li className="px-24 py-16 border-b border-b-neutral-50">
            <form onSubmit={handleSubmit}>
              <div className="flex w-full justify-center group rounded-lg border border-neutral-50 hover:border-violet-300">
                <input
                  type="text"
                  className="flex-1 bg-white/20 p-16 pr-50  text-neutral-90 text-lg group-focus:border-violet-300 outline-none transition-colors duration-300"
                  placeholder="Search..."
                  onChange={(e) => setQuery(e.target.value)}
                />
                <button disabled={query === ''} aria-label="search-button-with-icon" className="p-5 items-center pointer-events-none cursor-pointer">
                  <SearchIco />
                </button>
              </div>
              </form>
            </li>
            {/* 2nd level navigation */}
            {content?.fields?.navigationSubSection?.fields?.menus.map(
              (menuitem, index) => {
                const submenulist =
                  menuitem?.fields?.menusDropdown &&
                  menuitem?.fields?.menusDropdown.slice(
                    0,
                    menuitem?.fields?.menusDropdown.length - 1
                  );
                const lastItem =
                  menuitem?.fields?.menusDropdown &&
                  menuitem?.fields?.menusDropdown[
                    menuitem?.fields?.menusDropdown.length - 1
                  ];

                return (
                  <li key={`menuItems-${index}`}>
                    {!isOpenSubmenu && (
                      <div
                        onClick={() => handleSubMenu(submenulist, lastItem)}
                        className="cursor-pointer"
                      >
                        <NavComponent
                          name={menuitem?.fields?.groupName}
                          variant="Navmobile"
                          fontColor="text-black"
                          display={true}
                        />
                      </div>
                    )}
                  </li>
                );
              }
            )}

            {/* SUbmenu */}
            {isOpenSubmenu && (
              <div className={`w-full h-full flex bg-neutral-10`}>
                <div className="relative w-full pb-32 bg-neutral-10  ">
                  <div
                    className="w-full p-24 border-b border-neutral-50 cursor-pointer"
                    onClick={() => closeNavSuper()}
                  >
                    <TextButtonWithIcon
                      variant="dark"
                      name=""
                      iconPosition="start"
                      arrowDirection="left"
                      onClick={() => closeNavSuper()}
                    />
                  </div>
                  <ul>
                    {subMenu.map((menuDropdownitem, index) => (
                      <li
                        key={`dropdownMenu-${index}`}
                        className="mobileSubMenu"
                      >
                        <NavComponent
                          name={menuDropdownitem?.fields?.menuName}
                          link={menuDropdownitem?.fields?.externelLink}
                          variant="Navmobile"
                          fontColor="text-black"
                          display={true}
                          arrowDirection="down"
                        />
                        {menuDropdownitem?.fields?.menuDecription && (
                          <ul className="px-24 submenuContaner">
                            <li className="py-16 px-16">
                              <Paragraph
                                content={documentToHtmlString(
                                  menuDropdownitem?.fields?.menuDecription
                                )}
                                classes="flex items-start text-base textlink"
                              />
                            </li>
                          </ul>
                        )}
                      </li>
                    ))}
                    <li className="px-24 py-16">
                      <div className="list flex flex-col items-start ">
                        <NavComponent
                          name={lastItem?.fields?.menuName}
                          link={lastItem?.fields?.externelLink}
                          variant="LinkArrow"
                          fontColor="text-black"
                          classes="mb-8"
                        />
                      </div>
                    </li>
                    <li className="py-16 pl-32">
                      <Paragraph
                        content={documentToHtmlString(
                          lastItem?.fields?.menuDecription
                        )}
                        classes="flex items-start text-base textlink"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            )}

            {/* 1st level navigation */}
            {content?.fields?.navigationSection?.fields?.menus.map(
              (item, index) => (
                <li className="px-24 py-16">
                  <TextButton
                    variant="dark"
                    fontColor="text-black"
                    name={item?.fields?.groupName}
                    classes="text-neutral-100 hover:underline"
                    link={item?.fields?.externelLink}
                  />
                </li>
              )
            )}
          </ul>
        </div>
      </div>
      <div className="absolute bottom-0 z-50 w-full flex justify-center p-24 border-t border-t-neutral-50 bg-neutral-10 ">
        <OutlineButton
          variant="dark"
          name={
            content?.fields?.navigationSection?.fields?.register?.fields
              ?.ctaText
          }
          link={
            content?.fields?.navigationSection?.fields?.login?.fields
              ?.buttonLinkExternal
          }
          classes="mr-12 w-50"
        />
        <SecondaryButton
          name={
            content?.fields?.navigationSection?.fields?.login?.fields?.ctaText
          }
          link={
            content?.fields?.navigationSection?.fields?.register?.fields
              ?.buttonLinkExternal
          }
          classes="w-50"
        />
      </div>
    </div>
  );
};
export default MobileMenu;
