import * as React from 'react'
import { useEffect, useState } from 'react'
import { UseDailyUnitPriceAPI } from '../../../Hooks/useAPI'
import Selector from '../../Generic/Selector'
import Paragraph from '../../Generic/Paragraph'
import Headings from '../../Generic/Heading'
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"
import { ReactComponent as ArrowUpIcon } from '../../../Assets/arrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../Assets/arrowDown.svg'
import './style.css'
import Chart from './chart'
import InputComp from '../../Generic/Form/Input'
import TextButton from '../../Generic/Button/Text/Button'

const DailyUnitPrice = () => {
    const { isMobile } = useDeviceDetector()

    const [loading, setLoading] = useState(true)

    const [responseData, setResponseData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [displayData, setDisplayData] = useState([])

    const [products, setProducts] = useState([])
    const [diversified, setDiversified] = useState([])
    const [assetClass, setAssetClass] = useState([])

    const [selectedFilters, setSelectedFilters] = useState([])
    const [selectedProduct, setSelectedProduct] = useState("")
    const [selectedStartDate, setSelectedStartDate] = useState("")
    const [selectedEndDate, setSelectedEndDate] = useState("")

    const [activeTab, setActiveTab] = useState(0)
    const [activeMobileTab, setActiveMobileTab] = useState(false)
    const [selectedDisplayType, setSelectedDisplayType] = useState("Table")
    const [activeDisplayTab, setActiveDisplayTab] = useState(0)
    const [activeMobileDisplayTab, setActiveMobileDisplayTab] = useState(false)

    const [checkAll, setCheckAll] = useState(false)
    const [checkSelectAll, setCheckSelectAll] = useState("")

    const [allColumns, setAllColumns] = useState([])
    const [errorMsg, setErrorMsg] = useState("")

    const assetClasses = ['Shares Only', 'Cash']
    const prods = {Pension: 'Retirement Income Stream', Super: 'Accumulation Plan'}
    const displayType = ["Table", "Chart"]
    const selectAll = ["Select All", "Deselect All"]

    const formatDate = (dateString: Date, append?: string) => {
        const day = dateString.toLocaleString('default', {day: '2-digit'})
        const month = dateString.toLocaleString('default', {month: '2-digit'})
        const year = dateString.toLocaleString('default', {year: 'numeric'})
        const formattedDate =  `${year}-${month.length === 1 ? '0'+month : month}-${day.length === 1 ? '0'+day : day}${append}`
        return formattedDate
    }

    // Generate data based on date selection
    const handleDateRange = (e, value) => {
        e.preventDefault()
        e.stopPropagation()
        const today = new Date()
        if (value === 'Previous 12 months') {
            const last12 = new Date()
            const lastYear = new Date(last12.setMonth(last12.getMonth() - 12))
            const formattedStart = formatDate(lastYear, "")
            const formattedEnd = formatDate(today, "")

            setSelectedStartDate(formattedStart)
            setSelectedEndDate(formattedEnd)
        }
        if (value === 'Financial year to date') {
            const lastFinancial = new Date()
            if ((lastFinancial.getMonth() + 1) > 4) {
                const finDate = new Date(`04-01-${today.getFullYear()}`)
                const formattedStart = formatDate(finDate,"")
                const formattedEnd = formatDate(today, "")

                setSelectedStartDate(formattedStart)
                setSelectedEndDate(formattedEnd)
            } else {
                const finDate = new Date(`04-01-${today.getFullYear() - 1}`)
                const formattedStart = formatDate(finDate,"")
                const formattedEnd = formatDate(today, "")

                setSelectedStartDate(formattedStart)
                setSelectedEndDate(formattedEnd)
            }
        }
    }

    // Generate default date
    const defaultDateRange = () => {
        const today = new Date()
        let last15Days = new Date()
        last15Days = new Date(last15Days.setDate(last15Days.getDate() - 15))
        const formattedStart = formatDate(last15Days, "")
        const formattedEnd = formatDate(today, "")

        setSelectedStartDate(formattedStart)
        setSelectedEndDate(formattedEnd)
    }

    // Getting filters selection select all / deselect all
    const handleSelectAll = (checked, value) => {
        if (value === 'Select All') {
            setCheckAll(true)
           setSelectedFilters(allColumns)
        } else {
            setCheckAll(false)
            setSelectedFilters([])
        }
        setCheckSelectAll(value)
    }

    // Getting filters selection
    const handleInvestmentOptions = (checked, value) => {
        if (checked) {
            setSelectedFilters([...selectedFilters, value])
        } else {
            const index = selectedFilters.indexOf(value)
            selectedFilters.splice(index, 1)
            setSelectedFilters([...selectedFilters])
        }
        
        setCheckSelectAll("")
    }

    // Getting Start Date selection
    const selectStartDate = (name, value) => {
        setSelectedStartDate(value)
    }
    
    // Getting End Date selection
    const selectEndDate = (name, value) => {
        setSelectedEndDate(value)
    }

    // Toggle product options
    const toggleProducts = (e, index, value) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveTab(index)
        setActiveMobileTab(!activeMobileTab)
        setSelectedProduct(value)
    }

    // Toggle product options for mobile
    const toggleProductsMobile = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const target = e.target
        target.classList.toggle('active')
        setActiveMobileTab(!activeMobileTab)
    }
    
    // Selecting product options for Mobile
    const selectProductsMobile = (e, index, value) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveTab(index)
        setActiveMobileTab(!activeMobileTab)
        setSelectedProduct(value)
    }

    // Toggle display options
    const toggleDisplayOptions = (e, index, value) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveDisplayTab(index)
        setSelectedDisplayType(value)
    }

    // Toggle display options for mobile
    const toggleDisplayOptionsMobile = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const target = e.target
        target.classList.toggle('active')
        setActiveMobileDisplayTab(!activeMobileDisplayTab)
    }
    
    // Selecting display options for Mobile
    const selectDisplayOptionsMobile = (e, index, value) => {
        e.preventDefault()
        e.stopPropagation()
        setActiveDisplayTab(index)
        setActiveMobileDisplayTab(!activeMobileDisplayTab)
        setSelectedDisplayType(value)
    }

    // Serialize the data based on tab and filter selection
    const tableData = () => {
        let dateArr = []
        let allData = []
        setErrorMsg("")

        let data = filteredData[selectedProduct]

        if (selectedStartDate !== "" && selectedEndDate !== "") {
            const startDate: any = new Date(selectedStartDate)
            const endDate: any = new Date(selectedEndDate)
            if (selectedEndDate >= selectedStartDate) {
                const noOfDays = Math.floor((endDate - startDate)/(1000*60*60*24))

                const formattedDate = formatDate(endDate, 'T00:00:00.000Z')
                dateArr.push(formattedDate)

                for (let d = 0; d < noOfDays; d++) {
                    const nextDate = endDate.setDate(endDate.getDate() - 1)
                    const formattedDate = formatDate(new Date(nextDate), 'T00:00:00.000Z')
                    dateArr.push(formattedDate)
                }

                const filteredObj = {}
                dateArr?.forEach((item) => {
                    if (data[item]) {
                        filteredObj[item] = data[item]
                    }
                })
                data = filteredObj
            } else {
                setErrorMsg("End date should not be before start date")
            }
        }

        allData = Object.keys(data).map((obj) => {return {...data[obj], date: obj}})

        let headings = []

        if (selectedFilters.length === 0) {
            allData?.forEach((item) => {
                Object.keys(item).forEach((obj) => {
                    if (!headings.includes(obj) && obj !== 'date') {
                        headings.push(obj)
                    }
                })
            })
            setAllColumns(headings)
        } else {
            headings = selectedFilters
        }

        let values = []
        allData?.forEach((item) => {
            let filterObj = {}
            headings.forEach((obj) => {
                filterObj[obj] = item[obj]
            })
            const date = new Date(item['date'])
            const unitDate = date.toLocaleDateString('default', {weekday: 'long'}) + ', ' + date.toLocaleDateString('default', {day: 'numeric'}) + ' ' + date.toLocaleDateString('default', {month: 'long', year: 'numeric'})
            filterObj['date'] = unitDate
            values.push(filterObj)
        })

        setDisplayData([{rowHeading: headings, rowValues: values}])
    }

    // Generate/Serialize the data for table, tabs and filters
    const generateTableFiltersAndTabs = () => {
        let productData = []
        let diversifiedData = []
        let assetClassData = []
        let dataObj = []
        let prodObj = []

        responseData?.forEach((item) => {
            if (item?.ResultMessage) {
                const type = item?.InvestmentManager.split(' - ')[1]
                const prod = item?.InvestmentManager.split(' - ')[0]

                if (!productData.includes(prod)) {
                    productData.push(prod)
                }
                if (!diversifiedData.includes(type) && !assetClasses.includes(type)) {
                    diversifiedData.push(type)
                }
                if (!assetClassData.includes(type) && assetClasses.includes(type)) {
                    assetClassData.push(type)
                }

                let filterObj = {}
                filterObj[type] = item?.UnitPrice
                dataObj[item?.UnitPriceDate] = {...dataObj[item?.UnitPriceDate], ...filterObj}
                
                prodObj[prod] = {...prodObj[prod], ...dataObj}
            }
        })

        setFilteredData(prodObj)
        setProducts(productData)
        setDiversified(diversifiedData)
        setAssetClass(assetClassData)
        setSelectedProduct(productData[0])
        defaultDateRange()
    }
    
    // Getting daily unit price data from the API
    const getData = async () => {
        let response = await UseDailyUnitPriceAPI()
        setResponseData(response?.data?.recordset)
    }

    useEffect(() => {
        if (displayData.length === 0) return
        setLoading(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayData])

    useEffect(() => {
        if (Object.keys(filteredData).length === 0) return
        tableData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData, selectedFilters, selectedStartDate, selectedEndDate])

    useEffect(() => {
        if (responseData.length === 0) return
        generateTableFiltersAndTabs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (<>
        <div className='layoutSpacing'>
            <div className='w-full layout sectionSpacing dailyUnitPrice'>
                {!loading && 
                    <div className='flex flex-col gap-24'>
                        <div className='products flex flex-col gap-24'> 
                            <Headings variant='H3' content='Select your product' classes='text-purple-100 !pb-0' />
                            <div className='flex flex-wrap gap-24'>
                                {!isMobile ?
                                    <div className={`border border-neutral-50 rounded-lg overflow-hidden w-max`}>
                                        <div className={`tabHeading flex`}>
                                            {products?.map((item, index) =>
                                                <div key={`${item}-${index}`} data-item={item} className={`tabs px-24 py-16 cursor-pointer border-r border-neutral-50 overflow-hidden ${activeTab === index ? 'active' : ''} ${index === 0 ? 'border-r rounded-tl-lg rounded-bl-lg' : index === products?.length - 1 ? 'border-none rounded-tr-lg rounded-br-lg' : ''}`} onClick={(e) => {toggleProducts(e, index, item)}}>
                                                    <Paragraph content={prods[item]} />
                                                </div> 
                                            )}
                                        </div>
                                    </div>
                                :
                                    <div className={`relative mobileTab`}>
                                        <div className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden`} onClick={(e) => {toggleProductsMobile(e)}}>
                                            <div className={`selected flex justify-start items-center gap-12 cursor-pointer`}>
                                                <Paragraph content={prods[products[activeTab]]} />
                                            </div>
                                            <div className="">
                                                {activeMobileTab}
                                                {activeMobileTab && <ArrowUpIcon />}
                                                {!activeMobileTab && <ArrowDownIcon />}
                                            </div>
                                        </div>
                                        
                                        <div className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 overflow-hidden shadow-xl ${activeMobileTab ? 'active' : ''}`}>
                                            {products?.map((item, index) =>
                                                <div key={`${item}-${index}`} data-item={item} className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`} onClick={(e) => {selectProductsMobile(e, index, item)}}>
                                                    <Paragraph content={prods[item]} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className='timeFrame flex flex-col'>
                            <Headings variant='H3' content='Select a timeframe' classes='text-purple-100 !pb-24' />
                            <div className='flex flex-wrap gap-24 items-center'>
                                <div className='flex gap-24'>
                                    <InputComp
                                        label="From date"
                                        name="fromDate"
                                        placeholder=""
                                        error={false}
                                        errorMessage=""
                                        variant="date"
                                        required={false}
                                        value={selectedStartDate}
                                        className={'!p-0'}
                                        onChange={(name, value) => {selectStartDate(name, value)}}
                                    />
                                    <InputComp
                                        label="To date"
                                        name="toDate"
                                        placeholder=""
                                        error={false}
                                        errorMessage=""
                                        variant="date"
                                        required={false}
                                        value={selectedEndDate}
                                        className={'!p-0'}
                                        onChange={(name, value) => {selectEndDate(name, value)}}
                                    />
                                </div>
                                <div onClick={(e) => {handleDateRange(e, "Financial year to date")}}>
                                    <TextButton name='Financial year to date' variant='dark' underline={true} link="" buttonType='submit' classes='!text-black' />
                                </div>
                                <div onClick={(e) => {handleDateRange(e, "Previous 12 months")}}>
                                    <TextButton name='Previous 12 months' variant='dark' underline={true} link="" buttonType='submit' classes='!text-black' />
                                </div>
                            </div>
                            <Paragraph content={errorMsg} classes={`text-danger-100 !pt-24 ${errorMsg !== "" ? 'block' : 'hidden'}`} />
                        </div>
                        <div className='investmentOptions flex flex-col gap-24'> 
                            <Headings variant='H3' content='Select investment options' classes='text-purple-100 !pb-0' />
                            <div className='flex flex-wrap gap-24'>
                                {selectAll?.map((item, index) =>
                                    <div key={`${item}-${index}`} className='flex'>
                                        <Selector type='radio' value={item} name={item} checked={checkSelectAll === item ? true : false} onChange={(checked, value) => handleSelectAll(checked, value)} className='!p-0' />
                                        <Paragraph content={item} />
                                    </div>
                                )}
                            </div>
                            <Headings variant='H4' content='Diversified options' classes='text-purple-100 !pb-0' />
                            <div className='flex flex-wrap gap-24'>
                                {diversified?.map((item, index) =>
                                    <div key={`${item}-${index}`} className='flex'>
                                        <Selector type='checkbox' value={item} name={item} checked={checkAll} onChange={(checked, value) => handleInvestmentOptions(checked, value)} className='!p-0' />
                                        <Paragraph content={item} />
                                    </div>
                                )}
                            </div>
                            <Headings variant='H4' content='Single asset class options' classes='text-purple-100 !pb-0' />
                            <div className='flex flex-wrap gap-24'>
                                {assetClass?.map((item, index) =>
                                    <div key={`${item}-${index}`} className='flex'>
                                        <Selector type='checkbox' value={item} name={item} checked={checkAll} onChange={(checked, value) => handleInvestmentOptions(checked, value)} className='!p-0' />
                                        <Paragraph content={item} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='displayOptions flex flex-col gap-24'>
                            <Headings variant='H3' content='Select your display option' classes='text-purple-100 !pb-0' />
                            <div className='flex flex-wrap gap-24'>
                                {!isMobile ?
                                    <div className={`border border-neutral-50 rounded-lg overflow-hidden w-max`}>
                                        <div className={`tabHeading flex`}>
                                            {displayType?.map((item, index) =>
                                                <div key={`${item}-${index}`} data-item={item} className={`tabs px-24 py-16 cursor-pointer border-r border-neutral-50 overflow-hidden ${activeDisplayTab === index ? 'active' : ''} ${index === 0 ? 'border-r rounded-tl-lg rounded-bl-lg' : index === displayType?.length - 1 ? 'border-none rounded-tr-lg rounded-br-lg' : ''}`} onClick={(e) => {toggleDisplayOptions(e, index, item)}}>
                                                    <Paragraph content={item} />
                                                </div> 
                                            )}
                                        </div>
                                    </div>
                                :
                                    <div className={`relative mobileTab`}>
                                        <div className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden`} onClick={(e) => {toggleDisplayOptionsMobile(e)}}>
                                            <div className={`selected flex justify-start items-center gap-12 cursor-pointer`}>
                                                <Paragraph content={displayType[activeDisplayTab]} />
                                            </div>
                                            <div className="">
                                                {activeMobileDisplayTab && <ArrowUpIcon />}
                                                {!activeMobileDisplayTab && <ArrowDownIcon />}
                                            </div>
                                        </div>
                                        
                                        <div className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 overflow-hidden shadow-xl ${activeMobileDisplayTab ? 'active' : ''}`}>
                                            {displayType?.map((item, index) =>
                                                <div key={`${item}-${index}`} data-item={item} className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`} onClick={(e) => {selectDisplayOptionsMobile(e, index, item)}}>
                                                    <Paragraph content={item} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className=''>
                                {selectedDisplayType === 'Table' && 
                                    <div className={`text-container`}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <th>&nbsp;</th>
                                                    {displayData[0]?.rowHeading?.map((item) => 
                                                        <th>{item}</th>
                                                    )}
                                                </tr>
                                                {displayData[0]?.rowValues?.map((item) => <tr>
                                                    <td>{item['date']}</td>
                                                    {displayData[0]?.rowHeading.map((filter) => 
                                                        <td>{item[filter]}</td>
                                                    )}
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {selectedDisplayType === 'Chart' && <div className={``}>
                                    <Chart content={displayData} />
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {loading && 
                    <div className='flex flex-col gap-24'>
                        <Headings variant='H3' content='Loading ...' classes='text-purple-100' />
                    </div>
                }
            </div>
        </div>
    </>)
}

export default DailyUnitPrice