import * as React from "react"
import { useState } from "react"
import Paragraph from "../../Generic/Paragraph"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import Headings from "../../Generic/Heading"
import Tag from "../../Generic/Tag"
import OutlineButtonOnlyIcon from "../../Generic/Button/Outline/IconButton"
import PrimaryButton from "../../Generic/Button/Primary/Button"
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import Image from "../../Generic/Image"

interface Props {
    eventsData?: any
    content?: any
    background?: string
    textAlignment?: string
}

/**
 * Event Cards Carousel Component
 * @param eventsData - Event card data.
 * @param content - Content from the CMS.
 */
const EventCardsCarousel = ({eventsData, content}: Props) => {
    const { isMobile, isTablet, isDesktop } = useDeviceDetector()
    const [value, setValue] = useState(0)
    const [leftArrow, setLeftArrow] = useState(true)
    const [rightArrow, setRightArrow] = useState(false)

    const column = isMobile ? 1 : isTablet ? 2 : isDesktop ? 4 : 1

    const getWidth = (eventCarousel) => {
        const carouselList = eventCarousel.querySelector('.carousel')
        const carouselItem: any = carouselList.querySelectorAll('.carousel-item')
        const itemCount: any = carouselItem.length
        const itemWidth: any = carouselItem[0]?.offsetWidth
        return { itemWidth: itemWidth, carouselWidth: itemWidth * (itemCount - column) , lastItemWidth: carouselItem[carouselItem.length -1]?.offsetWidth }
    }

    const slideLeft = (e) => {
        e.stopPropagation()
        setRightArrow(false)
        const eventCarousel = e.target.closest('.eventCarousel')
        const carouselList = eventCarousel.querySelector('.carousel')
        const width = getWidth(eventCarousel)
        const translate = value - width?.itemWidth
        if (translate >= 0) {
            carouselList.style.transform = `translate3d(-${translate}px, 0px, 0px)`
            setValue(translate)
        }
        if (translate === 0) {
            setLeftArrow(true)
        }
    }

    const slideRight = (e) => {
        e.stopPropagation()
        setLeftArrow(false)
        const eventCarousel = e.target.closest('.eventCarousel')
        const carouselList = eventCarousel.querySelector('.carousel')
        const width = getWidth(eventCarousel)
        const translate =  width?.itemWidth + value
        if (translate <= width?.carouselWidth) {
            carouselList.style.transform = `translate3d(-${translate}px, 0px, 0px)`
            setValue(translate)
        }
        if (translate >= width?.carouselWidth) {
            setRightArrow(true)
        }
    }

    return (
        <div className="eventCarousel">
            <div className="carousel flex gap-24" style={{"transitionDuration": "0ms", "transform": "translate3d(0px, 0px, 0px)", "transitionDelay": "0ms"}}>
                {eventsData?.map((item, index) =>
                    <div key={`${item?.fields?.eventId}-${index}`} className={`carousel-item flex w-[100%] md:w-[50%] lg:min-w-[25%] xl:w-[20%]`}>
                        <div className="flex flex-col border border-neutral-50 rounded-2xl overflow-hidden p-32 hover:border-purple-100">
                            <Headings variant="H4" content={`${item?.fields?.eventTopic ? `${item?.fields?.eventTopic} - ${item?.fields?.title}` : item?.fields?.title}`} classes="text-purple-100 pb-16" />
                            <Paragraph content={documentToHtmlString(item?.fields?.eventDescription)} classes="line-clamp-3" />
                            {item?.fields?.fundTypeValue && 
                                <div className="pt-32">
                                    <Tag name={item?.fields?.fundTypeValue} />
                                </div>
                            }
                            <div className="py-32 grow">
                                {item?.fields?.eventLocation && <div className="flex items-center gap-8 pb-16">
                                    <Image imageUrl={item?.fields?.locationIcon?.fields?.file?.url} altText={item?.fields?.locationIcon?.fields?.file?.title} />
                                    <Paragraph content={item?.fields?.eventLocation}/>
                                </div>
                                }
                                {item?.fields?.startDate && <div className="flex items-center gap-8">
                                    <Image imageUrl={item?.fields?.eventCalendarIcon?.fields?.file?.url} altText={item?.fields?.eventCalendarIcon?.fields?.file?.title} />
                                    <Paragraph content={`${new Date(item?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'})} ${new Date(item?.fields?.startDate).toLocaleDateString('default', {month: 'long'})}`} />
                                </div>
                                }
                            </div>
                            {item?.fields?.registerButton && <TextButtonWithIcon variant="dark" name={item?.fields?.registerButton?.fields?.ctaText} link={`${content[content?.length - 1]?.fields?.buttonLinkExternal}?eventId=${item?.fields?.eventId}`} classes="cursor-pointer" />
                            }
                        </div>
                    </div>
                )}
                <div className="carousel-item flex items-center w-[100%] md:w-[50%] lg:min-w-[25%] xl:w-[20%]">
                    <PrimaryButton variant="dark" name={content[content?.length - 1]?.fields?.ctaText} link={content[content?.length - 1]?.fields?.buttonLinkExternal} />
                </div>
            </div>
            <div className="flex justify-end gap-24 mt-32">
                <div className="">
                    <PrimaryButton variant="dark" name={content[content?.length - 1]?.fields?.ctaText} link={content[content?.length - 1]?.fields?.buttonLinkExternal} />
                </div>
                <div className="navButtons flex gap-8">
                    <div onClick={(e) => {slideLeft(e)}}>
                        <OutlineButtonOnlyIcon variant="dark" buttonType="submit" arrowDirection="left" disabled={leftArrow} />
                    </div>
                    <div onClick={(e) => {slideRight(e)}}>
                        <OutlineButtonOnlyIcon variant="dark" buttonType="submit" disabled={rightArrow} />
                    </div>
                </div>
            </div>
        </div>)
}

export default EventCardsCarousel