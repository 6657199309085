import * as React from "react";
import { Helmet } from "react-helmet-async";

interface Props {
  content?: any;
}

/**
 * SEOMeta Component
 * @param content - Content from the CMS.
 */
const SEOMeta = ({ content }: Props) => {
  return (
    <Helmet >
      <meta property="og:type" content="website" />
      <title>{content?.fields?.pageTitle}</title>
      {content?.fields?.seo?.fields?.canonicalUrl && (
        <link
          rel="canonical"
          href={content?.fields?.seo?.fields?.canonicalUrl}
        />
      )}
      {content?.fields?.seo?.fields?.seodescription && (
        <meta
          name="description"
          content={content?.fields?.seo?.fields?.seodescription}
        />
      )}
      {content?.fields?.seo?.fields?.keywords && (
        <meta
          name="keywords"
          content={content?.fields?.seo?.fields?.keywords}
        />
      )}
      {content?.fields?.seo?.fields?.seoTitle && (
        <meta
          property="og:title"
          content={content?.fields?.seo?.fields?.seoTitle}
        />
      )}
      {content?.fields?.seo?.fields?.seodescription && (
        <meta
          property="og:description"
          content={content?.fields?.seo?.fields?.seodescription}
        />
      )}
      {content?.fields?.seo?.fields?.featuredimage && (
        <meta
          property="og:image"
          content={
            content?.fields?.seo?.fields?.featuredimage?.fields?.file?.url
          }
        />
      )}
    </Helmet>
  );
};

export default SEOMeta;
