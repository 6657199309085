import * as React from "react"
import {ReactComponent as CalculatorIcon} from '../../../Assets/calculate.svg'
import TextButtonWithIcon from "../Button/Text/ButtonWithIcon"
import Paragraph from "../Paragraph"

interface Props {
    content: string
}

/**
 * IconList Component
 * @param content - name of the tag.
 */

const IconList = ({content}: Props) => {
    return (<>
            <div data-testid="iconList" className="p-32 border border-bg-neutral-50">
                <div className="flex items-center gap-32">
                    <div>
                        <CalculatorIcon />
                    </div>
                    <div>
                        <TextButtonWithIcon name="Button Text" link="/" variant="dark" underline={true} classes="mb-8" />
                        <Paragraph content="Description Text" classes="!text-base" />
                    </div>
                </div>
            </div>
        </>
    )
    
}

export default IconList