import * as React from "react";
import { ReactComponent as Spinner } from "../../../../../Assets/spinner.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../../../Assets/arrowForward.svg";
import Image from "../../../Image";
import { buttonLinkClick } from "../../RenderButton";

interface Props {
  name: string;
  variant: string;
  buttonType?: "button" | "submit" | "reset";
  link?: string;
  loading?: boolean;
  disabled?: boolean;
  classes?: string;
  buttonIcon?: any;
  target?: string;
  componentName?: string
}

/**
 * Outline Button With Icon Component
 * @param name - Name of the button.
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param buttonType (optional) - Type of the button. Expected values:  "button" | "submit" | "reset", Default: "button"
 * @param link (optional) - Page to be navigate on click of the button.
 * @param loading (optional) - Loading of the button. Expected values:  true | false
 * @param disabled (optional) - Button to be disabled. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 * @param buttonIcon (optional) - Icon of the button.
 * @param target (optional) - Open url in new tab or not. Expected values: "_blank" | "_self". Default: "_self"
 * @param componentName (optional) - Name of the component from the contentful.
 */

const OutlineButtonWithIcon = ({
  name,
  variant,
  buttonType = "button",
  link,
  loading,
  disabled,
  classes,
  buttonIcon,
  target = "_self",
  componentName
}: Props) => {
  return (
    <>
      {variant === "light" && (
        <>
          <button
            aria-label={name}
            type={buttonType}
            className={`flex justify-center items-center text-base md:text-md rounded-lg 
            px-24 py-16
            ${
              loading
                ? "bg-neutral-30 text-purple-100 border-white border pointer-events-none"
                : "bg-transparent text-white border-neutral-50 border"
            }
            hover:bg-white hover:text-purple-100 hover:border-none
            focus:bg-white focus:text-purple-100 focus:border-white focus:border
            active:bg-neutral-30 active:text-purple-100 active:border-white active:border
            disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
            visited:bg-neutral-30 visited:text-purple-100 visited:border-white visited:border
            ${classes ? classes : ""}`}
            disabled={disabled}
            onClick={
              buttonType === "button"
                ? (e: any) => buttonLinkClick(e, name, link, target, componentName)
                : undefined
            }
            data-testid="lightOutlineButtonWithIcon"
          >
            {loading ? (
              <Spinner className="animate-spin" />
            ) : (
              <>
                <span className="mr-12">{name}</span>
                <span>
                  {buttonIcon ? (
                    <Image
                      imageUrl={buttonIcon?.fields?.file?.url}
                      altText={buttonIcon?.fields?.file?.title}
                    />
                  ) : (
                    <ArrowForwardIcon />
                  )}
                </span>
              </>
            )}
          </button>
        </>
      )}
      {variant === "dark" && (
        <>
          <button
            aria-label={name}
            type={buttonType}
            className={`flex justify-between items-center text-base md:text-md rounded-lg 
            px-24 py-12
            ${
              loading
                ? "bg-purple-hoverDark text-white border-purple-hoverDark border pointer-events-none"
                : "bg-transparent text-purple-100 border-purple-100 border"
            }
            hover:bg-purple-100 hover:text-white hover:border-purple-100
            focus:bg-purple-100 focus:text-white focus:border-purple-100 focus:border
            active:bg-purple-hoverDark active:text-white active:border-purple-100 active:border
            disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
            visited:bg-purple-hoverDark visited:text-white visited:border-purple-100 visited:border
            ${classes ? classes : ""}`}
            disabled={disabled}
            onClick={
              buttonType === "button"
                ? (e: any) => buttonLinkClick(e, name, link, target, componentName)
                : undefined
            }
            data-testid="darkOutlineButtonWithIcon"
          >
            {loading ? (
              <Spinner className="animate-spin" />
            ) : (
              <>
                <span className="mr-12 max-w-[95%]">{name}</span>
                <span>
                  {buttonIcon ? (
                    <Image
                      imageUrl={buttonIcon?.fields?.file?.url}
                      altText={buttonIcon?.fields?.file?.title}
                    />
                  ) : (
                    <ArrowForwardIcon />
                  )}
                </span>
              </>
            )}
          </button>
        </>
      )}
    </>
  );
};

export default OutlineButtonWithIcon;
