import * as React from "react"
import { buttonLinkClick } from "../../RenderButton"

interface Props {
  name: string
  link: string
  variant: string
  classes?: string
  fontColor?: string
  underline?: boolean
  buttonType?: string
  target?: string
  componentName?: string
}

/**
 * Text Button Component
 * @param name - Name of the text link.
 * @param link - Page to be navigate on click of the link text.
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param fontColor (optional) - Color of the text. Expected Tailwindcss classes
 * @param underline (optional) - Text underline. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 * @param target (optional) - Open url in new tab or not. Expected values: "_blank" | "_self". Default: "_self"
 * @param componentName (optional) - Name of the component from the contentful.
 */

const TextButton = ({ name, link, variant, fontColor, underline, classes, buttonType, target = "_self", componentName }: Props) => {

  return (
    <>
      {variant === "light" && (<>
        <a data-testid="textButtonLight" href={link} className={`flex items-start text-base md:text-md rounded-lg border border-transparent font-semibold
          ${fontColor ? fontColor : 'text-white'}
          ${classes ? classes : 'justify-center'}
          focus:focus:border focus:border-white focus:border-opacity-20 
          active:text-primary-hoverLight`}
          onClick={buttonType === 'button' ? (e: any) => buttonLinkClick(e, name, link, target, componentName) : undefined}
        >
          <span className={`${underline ? 'underline underline-offset-4' : 'no-underline'}`}>{name}</span>
        </a>
        </>
      )}
      {variant === "dark" && (<>
        <a data-testid="textButtonDark" href={link} className={`flex items-start text-base md:text-md rounded-lg border border-transparent font-semibold
          ${fontColor ? fontColor : 'text-purple-100'}
          ${classes ? classes : 'justify-center'}
          focus:focus:border focus:border-purple-100 focus:border-opacity-20 
          active:text-primary-hoverDark`}
          onClick={buttonType === 'button' ? (e: any) => buttonLinkClick(e, name, link, target, componentName) : undefined}
        >
          <span className={`${underline ? 'underline underline-offset-4' : 'no-underline'}`}>{name}</span>
        </a>
        </>
      )}
    </>
  )
}

export default TextButton
