import * as React from "react"
import Paragraph from "../../Generic/Paragraph"
import Headings from "../../Generic/Heading"
import {ReactComponent as InfoTealIcon} from '../../../Assets/infoTeal.svg'
import {ReactComponent as InfoPurpleIcon} from '../../../Assets/infoPurple.svg'
import {ReactComponent as WarningIcon} from '../../../Assets/warning.svg'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

interface Props {
    background: string
    content?: any
}

/**
 * Alert Component
 * @param content - Content from the CMS.
 * @param background - Background of the Alert. Expected values: "teal" | "purple" | "orange", Default: "teal"
 */
const Alert = ({background = 'teal', content}: Props) => {
    background = background.toLowerCase()
    const bgColor = background === "purple" ? "bg-purple-100" : background === "orange" ? "bg-orange-10" : background === "teal" ? "bg-teal-10" : background === "lightpurple" ? "bg-purple-10" : "bg-white"
    const borderColor = background === "purple" ? "border-purple-100" : background === "orange" ? "border-orange-100" : background === "teal" ? "border-teal-100" : background === "lightpurple" ? "border-purple-100" : "border-none"

    return (<>
        <div className="layoutSpacing">
            <div className={`w-100 sectionBottomSpacing layout`} data-testid="alert">
                <div className={`p-32 border rounded-2xl ${bgColor} ${borderColor}`}>
                    <div className="flex items-center gap-32">
                        <div>
                            {background === 'orange' && <WarningIcon />}
                            {background === 'teal' && <InfoTealIcon />}
                            {background === 'purple' && <InfoPurpleIcon />}
                        </div>
                        <div>
                            <Headings variant="H4" content={content?.title} classes="text-black mb-8" />
                            <Paragraph content={documentToHtmlString(content?.message)} classes="!text-base md:!text-md" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Alert