import * as React from "react"
import { useEffect, useState } from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import PrimaryButtonWithIcon from "../../Generic/Button/Primary/ButtonWithIcon"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import Image from "../../Generic/Image"
import MoreEvents from './MoreEvents'

interface Props {
    content?: any,
    eventDetailsData?: any
    eventsData?: any
}

/**
 * Event Details Component
 * @param content - Content from the CMS.
 */
const EventDetails = ({content, eventDetailsData, eventsData}: Props) => {
    const [moreDates, setMoreDates] = useState([])

    useEffect(() => {
        if (!eventDetailsData || eventDetailsData.length === 0 || eventsData.length === 0) return
        
        const startDate = eventDetailsData[0]?.fields?.startDate

        const moreDates = eventsData?.filter((item) => item !== eventDetailsData[0] && item?.fields?.startDate === startDate)
        moreDates.length > 0 && setMoreDates(moreDates)
        
    }, [content, eventsData, eventDetailsData])


    return (<>
        <div className={`layoutSpacing w-100 bg-white`}>
            <div className="layout">
                <div className="">
                    <TextButtonWithIcon variant="dark" name="Back to all events" underline={true}  iconPosition="start" arrowDirection="left" classes="!text-black cursor-pointer" link={window.location.pathname} />
                </div>
                <div className="sectionSpacing">
                    <div className="flex flex-wrap gap-32">
                        <div className={`w-100 xl:w-75`}>
                            <Headings variant="H2" content={`${eventDetailsData[0]?.fields?.eventTopic} - ${eventDetailsData[0]?.fields?.title}`} classes="text-purple-100" />
                            <Paragraph content={documentToHtmlString(eventDetailsData[0]?.fields?.eventDescription)} classes="w-100 xl:w-75" />
                        </div>
                        <div className={`grow shrink-0`}>
                            <div className="flex flex-col gap-32 grow">
                                <div className="flex flex-col items-start gap-8">
                                    <div className="flex items-center gap-8">
                                        <Image imageUrl={eventDetailsData[0]?.fields?.fundTypeIcon?.fields?.file?.url} altText={eventDetailsData[0]?.fields?.fundTypeIcon?.fields?.file?.title} />
                                        <Paragraph content={eventDetailsData[0]?.fields?.fundTypeLabel} classes="!text-sm"/>
                                    </div>
                                    <div className="flex pl-24">
                                        <Paragraph content={eventDetailsData[0]?.fields?.fundTypeValue}/>
                                    </div>
                                </div>
                                <div className="flex flex-col items-start gap-8">
                                    <div className="flex items-center gap-8">
                                        <Image imageUrl={eventDetailsData[0]?.fields?.presenterIcon?.fields?.file?.url} altText={eventDetailsData[0]?.fields?.presenterIcon?.fields?.file?.title} />
                                        <Paragraph content={eventDetailsData[0]?.fields?.presenterLabel} classes="!text-sm"/>
                                    </div>
                                    <div className="flex pl-24">
                                        <Paragraph content={eventDetailsData[0]?.fields?.eventPresenter1?.fields?.fullName} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`w-100 xl:w-75 rounded-2xl overflow-hidden hover:border-purple-100 p-24 md:p-32 bg-purple-10`}>
                            <div className="flex flex-col gap-32 grow">
                                <div className="flex flex-col items-start gap-8">
                                    <div className="flex items-center gap-8">
                                        <Image imageUrl={eventDetailsData[0]?.fields?.locationIcon?.fields?.file?.url} altText={eventDetailsData[0]?.fields?.locationIcon?.fields?.file?.title} />
                                        <Paragraph content="Location" classes="!text-sm"/>
                                    </div>
                                    <div className="flex pl-24">
                                        <Paragraph content={eventDetailsData[0]?.fields?.locationPhysicalAddress}/>
                                    </div>
                                </div>
                                <div className="flex flex-col items-start gap-8">
                                    <div className="flex items-center gap-8">
                                        <Image imageUrl={eventDetailsData[0]?.fields?.eventCalendarIcon?.fields?.file?.url} altText={eventDetailsData[0]?.fields?.eventCalendarIcon?.fields?.file?.title} />
                                        <Paragraph content="Date" classes="!text-sm"/>
                                    </div>
                                    <div className="flex pl-24">
                                        {/* Start date and end is different day */}
                                        {new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'}) !== new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString('default', {day: 'numeric'}) &&
                                            <Paragraph content={`${new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString('default', {weekday: 'long'})}, ${new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'})} ${new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString('default', {month: 'long'})} ${new Date(eventDetailsData[0]?.fields?.startDate).toLocaleTimeString('default', {hour12: true, hour: '2-digit', minute: '2-digit'})} - ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString('default', {weekday: 'long'})}, ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString('default', {day: 'numeric'})} ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString('default', {month: 'long'})} ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleTimeString('default', {hour12: true, hour: '2-digit', minute: '2-digit'})}`}/>
                                        }
                                        {/* Start date and end date is same day */}
                                        {new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'}) === new Date(eventDetailsData[0]?.fields?.endDate).toLocaleDateString('default', {day: 'numeric'}) &&
                                            <Paragraph content={`${new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString('default', {weekday: 'long'})}, ${new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'})} ${new Date(eventDetailsData[0]?.fields?.startDate).toLocaleDateString('default', {month: 'long'})} ${new Date(eventDetailsData[0]?.fields?.startDate).toLocaleTimeString('default', {hour12: true, hour: '2-digit', minute: '2-digit'})} - ${new Date(eventDetailsData[0]?.fields?.endDate).toLocaleTimeString('default', {hour12: true, hour: '2-digit', minute: '2-digit'})}`}/>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pt-32">
                        <PrimaryButtonWithIcon name="Register" variant="dark" link={eventDetailsData[0]?.fields?.onlineEventLink ? eventDetailsData[0]?.fields?.onlineEventLink : eventDetailsData[0]?.fields?.registerButton?.fields?.buttonLinkExternal} target={eventDetailsData[0]?.fields?.onlineEventLink ? '_blank' : '_self'} />
                    </div>
                </div>
            </div>
        </div>
        {moreDates.length > 0 && <MoreEvents background="purple" cardBackground="white" border={true} columnLayout="3" content={moreDates} />}
    </>)
}

export default EventDetails