import React, { useEffect, useState } from "react";
import { ReactComponent as CheckWhite } from "../../../Assets/checkWhite.svg";

import "./style.css";

interface SelectorProps {
  type: "checkbox" | "radio" | "switch";
  label?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  indeterminate?: boolean;
  className?: string;
  disabled?: boolean;
  onChange?: (checked: boolean, value: string) => void;
}

const Selector = ({
  type,
  label,
  name,
  value,
  checked = false,
  indeterminate,
  className = "",
  disabled = false,
  onChange,
}: SelectorProps) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChangeInput = (e) => {
    e.stopPropagation()
    setIsChecked(!isChecked);
    onChange(!isChecked, value);
  };

  useEffect(() => {
    setIsChecked(
      indeterminate ? indeterminate : checked
    )
  }, [checked])

  return (
    <>
      <label data-testid="SelectorBlock"
        className={`inline-flex item-center px-24 py-12 text-left cursor-pointer text-neutral-100 group ${className}`}
      >
        <span
          className={`flex relative items-center justify-center form-checkbox p-2 ${
            type === "radio" || type === "switch"
              ? "rounded-full"
              : "rounded-sm"
          } ${
            type === "switch" ? "w-7 h-16 mt-1.5 bg-neutral-50" : "w-5 h-5 mt-2"
          } mr-3 border 
            ${
              disabled
                ? "disabled:cursor-not-allowed"
                : type !== "switch" &&
                  "group-hover:border-purple-100 group-focus:outline outline-4 outline-purple-200"
            }
          ${
            indeterminate
              ? "bg-white"
              : isChecked
              ? disabled
                ? "bg-neutral-50 border-neutral-50"
                : `${type !== "radio" && "bg-purple-100"} border-purple-100`
              : "border-neutral-50"
          }`}
        >
          {type === "checkbox" ? (
            indeterminate ? (
              <span
                className={`inline-flex w-3 h-3 ${
                  disabled ? "bg-neutral-50" : "bg-purple-100"
                }  `}
              ></span>
            ) : (
              isChecked && <CheckWhite />
            )
          ) : null}
          {type === "radio" ? (
            <span
              className={`inline-flex w-3 h-3 border-1 border-collapse border-white rounded-full ${
                isChecked && "bg-purple-100"
              } ${disabled && "bg-neutral-50"}`}
            ></span>
          ) : null}
          {type === "switch" ? (
            <span
              className={`absolute w-3 h-3 border-1 border-collapse bg-white rounded-full transition-transform ${
                isChecked ? "translate-x-1.5" : "left-2"
              } ${disabled && "bg-neutral-50"}`}
            ></span>
          ) : null}
          <input
            type={type === "switch" ? "checkbox" : type}
            name={name}
            value={value}
            disabled={disabled}
            className="w-0 h-0 opacity-0 absolute"
            checked={isChecked}
            onChange={(e) => {handleChangeInput(e)}}
          />
        </span>
        {label && <span data-testid="labelElement" >{label}</span>}
      </label>
    </>
  );
};

export default Selector;
