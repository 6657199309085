import * as React from "react"
import { useEffect, useState } from "react"
import Paragraph from "../../Generic/Paragraph"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import Headings from "../../Generic/Heading"
import Tag from "../../Generic/Tag"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import EventCardsCarousel from "./Carousel"
import Image from "../../Generic/Image"
import OutlineButton from "../../Generic/Button/Outline/Button"

interface Props {
    eventsData?: any
  content?: any
  cardBackground?: string
  columnLayout?: string
  border?: boolean
  selectedTabs?: any
  carousel?: boolean,
  pageNumber?: number,
  setPageNumber?: any
}

/**
 * Event Cards Component
 * @param content - Content from the CMS.
 * @param eventsData - Event cards data from the Contentful
 * @param cardBackground (optional) - Background of the Card. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4" | "orange", Default: "white"
 * @param border (optional) - Outline/Border of the Card. Expected values: true | false, Default: false
 */

const EventCards = ({ content, eventsData, cardBackground = 'white', columnLayout = '2', border = false, selectedTabs, carousel = false, pageNumber, setPageNumber }: Props) => {
    const [data, setData] = useState([])
    const [carouselData, setCarouselData] = useState([])
    const [filteredEvents, setFilteredEvents] = useState([])
    const [showLoadMore, setShowLoadMore] = useState(true)
    const bgCardColor = cardBackground === 'purple' ? 'bg-purple-10' : cardBackground === 'orange' ? 'bg-orange-10' : cardBackground === 'teal' ? 'bg-teal-10' : 'bg-white'
    const columns = columnLayout === '3' ? 'xl:w-[32%]' : columnLayout === '4' ? 'xl:w-[23.6%]' : 'xl:w-[49%]'
    const layout = `md:w-[48.25%] lg:w-[48.7%] ${columns} md:max-w-[50%]`
    carousel = content[0]?.fields?.carousel

    const pageSize = 8

    const handlePagination = (e) => {
        e.stopPropagation()
        setPageNumber(pageNumber + 1)
        if (filteredEvents.length > 0) {
            if (pageNumber === filteredEvents.length / pageSize - 1) {
                setShowLoadMore(false)
            }
        } else {
            if (pageNumber === eventsData.length / pageSize - 1) {
                setShowLoadMore(false)
            }
        }
    }

    useEffect(() => {
        if (!eventsData || !selectedTabs || selectedTabs.length === 0) return
            
        let filterCondition = []

        filterCondition = selectedTabs?.map((item) => {if (item.tabItem !== 'All') {return item} return  null})
        filterCondition = filterCondition.filter((item) => item !== null)
    
        let filteredEvents = eventsData?.map((event) => {
            if (filterCondition.length === 1) {
                if (event?.fields[filterCondition[0]?.tabGroup] === filterCondition[0]?.tabItem) {
                    return event
                }
            }
            if (filterCondition.length === 2) {
                if (event?.fields[filterCondition[0]?.tabGroup] === filterCondition[0]?.tabItem && event?.fields[filterCondition[1]?.tabGroup] === filterCondition[1]?.tabItem) {
                    return event
                }
            }
            if (filterCondition.length === 3) {
                if (event?.fields[filterCondition[0]?.tabGroup] === filterCondition[0]?.tabItem && event?.fields[filterCondition[1]?.tabGroup] === filterCondition[1]?.tabItem && event?.fields[filterCondition[2]?.tabGroup] === filterCondition[2]?.tabItem) {
                    return event
                }
            }
            return null
        })
        filteredEvents = filteredEvents && filteredEvents.filter((item) => item !== null)
        if (filterCondition.length === 0) {
            const paginatedData = eventsData.slice(0, (pageSize*pageNumber))
            setData(paginatedData)
            setCarouselData(eventsData)
            setFilteredEvents([])
        } else {
            const paginatedData = filteredEvents.slice(0, (pageSize*pageNumber))
            setData(paginatedData)
            setFilteredEvents(filteredEvents)
            setCarouselData(filteredEvents)
        }

        if(pageNumber === 1) {
            setShowLoadMore(true)
            if (filteredEvents.length > 0) {
                if (filteredEvents.length <= pageSize) {
                    setShowLoadMore(false)
                }
            } else {
                if (eventsData.length <= pageSize) {
                    setShowLoadMore(false)
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventsData, selectedTabs, pageNumber])
  
  return (
    <>
    <div className="layoutSpacing">
        <div className={`w-full layout ${carousel ? 'sectionBottomSpacing' : 'sectionSpacing'} overflow-hidden`}>
            {carousel ?
                <EventCardsCarousel eventsData={carouselData} content={content} />
                :
                <>
                <div className="flex flex-wrap justify-start gap-24">
                    {data?.map((item, index) =>
                        <div key={`${item?.fields?.eventId}-${index}`} className={`flex ${border ? 'border border-neutral-50' : ''} rounded-2xl overflow-hidden hover:border-purple-100 ${bgCardColor} ${layout}`}>
                            <div className="p-24 md:p-32">
                                <Headings variant="H4" content={`${item?.fields?.eventTopic ? `${item?.fields?.eventTopic} - ${item?.fields?.title}` : item?.fields?.title}`} classes="text-purple-100 pb-16" />
                                <Paragraph content={documentToHtmlString(item?.fields?.eventDescription)} classes="line-clamp-3" />
                                {item?.fields?.fundTypeValue && 
                                    <div className="pt-32">
                                        <Tag name={item?.fields?.fundTypeValue} />
                                    </div>
                                }
                                <div className="py-32 grow">
                                    {item?.fields?.eventLocation && <div className="flex items-center gap-8 pb-16">
                                            <Image imageUrl={item?.fields?.locationIcon?.fields?.file?.url} altText={item?.fields?.locationIcon?.fields?.file?.title} />
                                            <Paragraph content={item?.fields?.eventLocation}/>
                                        </div>
                                    }
                                    {item?.fields?.startDate && <div className="flex items-center gap-8">
                                        <Image imageUrl={item?.fields?.eventCalendarIcon?.fields?.file?.url} altText={item?.fields?.eventCalendarIcon?.fields?.file?.title} />
                                        <Paragraph content={`${new Date(item?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'})} ${new Date(item?.fields?.startDate).toLocaleDateString('default', {month: 'long'})}`} />
                                    </div>
                                    }
                                </div>
                                {item?.fields?.registerButton && <TextButtonWithIcon variant="dark" name={item?.fields?.registerButton?.fields?.ctaText} link={`?eventId=${item?.fields?.eventId}`} classes="cursor-pointer" />
                                }
                            </div>
                        </div>
                    )}
                    {data?.length === 0 &&
                        <div>
                            <Paragraph content={"No events available. "}/>
                        </div>
                    }
                </div>
                {showLoadMore && 
                    <div className="flex justify-center pt-32" onClick={(e) => handlePagination(e)}>
                        <OutlineButton name="Load more" variant="dark" buttonType="submit" classes="cursor-pointer" />
                    </div>
                }
                </>
            }
        </div>
    </div>
    </>
  )
}

export default EventCards
