import * as React from "react"
import { ReactComponent as ArrowForwardIcon } from '../../../../../Assets/arrowForward.svg'
import { ReactComponent as ArrowBackIcon } from '../../../../../Assets/arrowBack.svg'
import Image from "../../../Image"
import { buttonLinkClick } from "../../RenderButton"

interface Props {
  name: string
  link?: string
  variant: string
  arrowDirection?: string
  iconPosition?: string
  fontColor?: string
  underline?: boolean
  classes?: string
  buttonIcon?: any
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  buttonType?: string
  target?: any
  componentName?: string
}

/**
 * Text Button With Icon Component
 * @param name - Name of the text link.
 * @param link (optional)- Page to be navigate on click of the link text.
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param arrowDirection (optional) - Direction for the arrow. Expected values: "left" | "right"
 * @param iconPosition (optional) - Position of the icon. Expected values: "start" | "end"
 * @param underline (optional) - Text underline. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 * @param buttonIcon (optional) - Icon of the button.
 * @param target (optional) - Open url in new tab or not. Expected values: "_blank" | "_self". Default: "_self"
 * @param componentName (optional) - Name of the component from the contentful.
 */

const TextButtonWithIcon = ({ name, link, variant, arrowDirection = 'right', iconPosition = 'end', fontColor, underline, classes, onClick, buttonIcon, buttonType = 'button', target = "_self", componentName  }: Props) => {
  
  return (
    <>
      {variant === "light" && (<>
        <div data-testid="textButtonWithIconLight" className="flex">
          <a  href={link} className={`flex text-base md:text-md rounded-lg border-4 border-transparent 
            ${fontColor ? fontColor : 'text-white'}
            ${classes ? classes : ''}
            focus:focus:border-4 focus:border-white focus:border-opacity-20 
            active:text-primary-hoverLight
            visited:text-primary-hoverLight`}
            onClick={buttonType === 'button' ? (e: any) => buttonLinkClick(e, name, link, target, componentName, onClick) : undefined}
          >
            {iconPosition === 'start' && (arrowDirection === "left" ? <span  className=""><ArrowBackIcon /></span> : <span className=""><ArrowForwardIcon /></span>)}
            <span  className={`${iconPosition === 'start'? 'ml-12' : 'mr-12'} ${underline ? 'underline underline-offset-4' : 'no-underline'}`}>{name}</span>
            {iconPosition === 'end' && (arrowDirection === "left" ? <span className=""><ArrowBackIcon /></span> : <span className=""><ArrowForwardIcon /></span>)}
          </a>
        </div>
        </>
      )}
      {variant === "dark" && (<>
        <div data-testid="textButtonWithIconDark" className="flex">
          <a href={link} className={`flex text-base md:text-md rounded-lg border-4 border-transparent 
            ${fontColor ? fontColor : 'text-purple-100'}
            ${classes ? classes : ''}
            focus:focus:border-4 focus:border-purple-100 focus:border-opacity-20 
            active:text-primary-hoverDark`}
            onClick={buttonType === 'button' ? (e: any) => buttonLinkClick(e, name, link, target, componentName, onClick) : undefined}
          >
            {iconPosition === 'start' && (arrowDirection === "left" ? <span className=""><ArrowBackIcon /></span> : <span className=""><ArrowForwardIcon /></span>)}
            <span className={`${iconPosition === 'start'? 'ml-12' : 'mr-12'} ${underline ? 'underline underline-offset-4' : 'no-underline'}`}>{name}</span>
            {iconPosition === 'end' && (arrowDirection === "left" ? <span className=""><ArrowBackIcon /></span> : <span className="">{buttonIcon ? <Image imageUrl={buttonIcon?.fields?.file?.url} altText={buttonIcon?.fields?.file?.title} /> : <ArrowForwardIcon />}</span>)}
          </a>
        </div>
        </>
      )}
    </>
  )
}

export default TextButtonWithIcon
