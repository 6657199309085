import * as React from "react"
import Paragraph from "../../Generic/Paragraph"
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon"
import Headings from "../../Generic/Heading"
import Tag from "../../Generic/Tag"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import Image from "../../Generic/Image"
import OutlineButtonWithIcon from "../../Generic/Button/Outline/ButtonWithIcon"

interface Props {
    eventsData?: any
  content?: any
  background?: any
  cardBackground?: string
  columnLayout?: string
  border?: boolean
  selectedTabs?: any
  carousel?: boolean
  setEventDetailsData?:any
}

/**
 * Event Cards Component
 * @param content - Content from the CMS.
 */

const MoreEvents = ({ content, eventsData, background = 'white', cardBackground = 'white', columnLayout = '2', border = false, setEventDetailsData }: Props) => {
    const bgCardColor = cardBackground === 'purple' ? 'bg-purple-10' : cardBackground === 'orange' ? 'bg-orange-10' : cardBackground === 'teal' ? 'bg-teal-10' : 'bg-white'
    const columns = columnLayout === '3' ? 'xl:w-[32%]' : columnLayout === '4' ? 'xl:w-[23.6%]' : 'xl:w-[49%]'
    const layout = `md:w-[48.25%] lg:w-[48.7%] ${columns} md:max-w-[50%]`
    const bgColor =
    background === "purple"
      ? "bg-purple-10"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : "bg-white";
  
  return (
    <>
    {content.length > 0 &&
        <div className={`${bgColor} layoutSpacing`}>
            <div className="w-full layout sectionSpacing overflow-hidden">
                <div className="w-full md:w-3/4">
                    <Headings
                        content={"More dates and times"}
                        variant="H2"
                        classes="text-purple-100"
                    />
                </div>
                <div className="flex flex-wrap justify-start gap-24 pt-32">
                    {content?.map((item, index) =>
                        <div key={`${item?.fields?.eventId}-${index}`} className={`flex ${border ? 'border border-neutral-50' : ''} rounded-2xl overflow-hidden hover:border-purple-100 ${bgCardColor} ${layout}`}>
                            <div className="p-24 md:p-32">
                                <Headings variant="H4" content={`${item?.fields?.eventTopic} - ${item?.fields?.title}`} classes="text-purple-100 pb-16" />
                                <Paragraph content={documentToHtmlString(item?.fields?.eventDescription)} classes="line-clamp-3" />
                                {item?.fields?.fundTypeValue && 
                                    <div className="pt-32">
                                        <Tag name={item?.fields?.fundTypeValue} />
                                    </div>
                                }
                                <div className="py-32 grow">
                                    <div className="flex items-center gap-8 pb-16">
                                        <Image imageUrl={item?.fields?.locationIcon?.fields?.file?.url} altText={item?.fields?.locationIcon?.fields?.file?.title} />
                                        <Paragraph content={item?.fields?.eventLocation}/>
                                    </div>
                                    <div className="flex items-center gap-8">
                                        <Image imageUrl={item?.fields?.eventCalendarIcon?.fields?.file?.url} altText={item?.fields?.eventCalendarIcon?.fields?.file?.title} />
                                        <Paragraph content={`${new Date(item?.fields?.startDate).toLocaleDateString('default', {day: 'numeric'})} ${new Date(item?.fields?.startDate).toLocaleDateString('default', {month: 'long'})}`} />
                                    </div>
                                </div>
                                <TextButtonWithIcon variant="dark" name={item?.fields?.registerButton?.fields?.ctaText} link={`?eventId=${item?.fields?.eventId}`} classes="cursor-pointer" />
                            </div>
                        </div>
                    )}
                </div>
                <div className="flex justify-center pt-32">
                    <OutlineButtonWithIcon name="See all other events" variant="dark" link={window.location.pathname} />
                </div>
            </div>
        </div>
    }
    </>
  )
}

export default MoreEvents
