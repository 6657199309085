import * as React from "react"

interface Props {
    name: string
}

/**
 * Tag Component
 * @param name - name of the tag.
 */

const Tag = ({name}: Props) => {

    return (<>
            <div data-testid="tag" className="text-sm text-black font-semibold bg-orange-10 px-12 py-8 rounded-lg w-max">{name}</div>
        </>
    )
    
}

export default Tag