import * as React from "react"
import { ReactComponent as DownloadIcon } from '../../../../../Assets/download.svg'
import Image from "../../../Image"
import { buttonLinkClick } from "../../RenderButton"

interface Props {
  name: string
  link: string
  variant: string
  iconPosition?: string
  fontColor?: string
  underline?: boolean
  classes?: string
  buttonIcon?: any
  target?: string
  componentName?: string
}

/**
 * Download Button With Icon Component
 * @param name - Name of the text link.
 * @param link - Page to be navigate on click of the link text.
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param iconPosition (optional) - Position of the icon. Expected values: "start" | "end"
 * @param underline (optional) - Text underline. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 * @param buttonIcon (optional) - Icon of the button.
 * @param target (optional) - Open url in new tab or not. Expected values: "_blank" | "_self". Default: "_self"
 * @param componentName (optional) - Name of the component from the contentful.
 */

const DownloadButtonWithIcon = ({ name, link, variant, iconPosition = 'end', fontColor, underline, classes, buttonIcon, target="_self", componentName }: Props) => {

  return (
    <>
      {variant === "light" && (<>
        <a href={link} aria-label={name} className={`flex justify-center items-center text-base md:text-md rounded-lg border border-transparent font-semibold
          ${fontColor ? fontColor : 'text-white'}
          ${classes ? classes : ''}
          focus:focus:border focus:border-white focus:border-opacity-20 
          active:text-primary-hoverLight`}
          onClick={(e: any) => buttonLinkClick(e, name, link, target, componentName)}
          data-testid="lightDownloadButton"
        >
          {iconPosition === 'start' && (buttonIcon ? <Image imageUrl={buttonIcon?.fields?.file?.url} altText={buttonIcon?.fields?.file?.title} /> : <DownloadIcon />)}
          <span className={`${iconPosition === 'start'? 'ml-12' : 'mr-12'} ${underline ? 'underline' : 'no-underline'}`}>{name}</span>
          {iconPosition === 'end' && (buttonIcon ? <Image imageUrl={buttonIcon?.fields?.file?.url} altText={buttonIcon?.fields?.file?.title} /> : <DownloadIcon />)}
        </a>
        </>
      )}
      {variant === "dark" && (<>
        <a href={link} aria-label={name} className={`flex justify-center items-center text-base md:text-md rounded-lg border border-transparent font-semibold
          ${fontColor ? fontColor : 'text-purple-100'}
          ${classes ? classes : ''}
          focus:focus:border focus:border-purple-100 focus:border-opacity-20 
          active:text-primary-hoverDark`}
          onClick={(e: any) => buttonLinkClick(e, name, link, target, componentName)}
          data-testid="darkDownloadButton"
        >
          {iconPosition === 'start' && (buttonIcon ? <Image imageUrl={buttonIcon?.fields?.file?.url} altText={buttonIcon?.fields?.file?.title} /> : <DownloadIcon />)}
          <span className={`${iconPosition === 'start'? 'ml-12' : 'mr-12'} ${underline ? 'underline' : 'no-underline'}`}>{name}</span>
          {iconPosition === 'end' && (buttonIcon ? <Image imageUrl={buttonIcon?.fields?.file?.url} altText={buttonIcon?.fields?.file?.title} /> : <DownloadIcon />)}
        </a>
        </>
      )}
    </>
  )
}

export default DownloadButtonWithIcon
