import * as React from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import Breadcrumbs from "../Breadcrumbs"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

interface Props {
    content?: any
    background?: string
    breadcrumb?: any
}

/**
 * Page Banner Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "lightPurple" | "orange", Default: "purple"
 */
const PageBanner = ({content, background = 'lightPurple', breadcrumb}: Props) => {
    background = background.toLowerCase()
    const bgColor = background === "purple" ? "bg-purple-100" : background === "orange" ? "bg-orange-10" : background === "teal" ? "bg-teal-10" : background === "lightpurple" ? "bg-purple-10" : "bg-white"
    return (<>
        <div data-testid="pageBanner" className={`layoutSpacing w-100 ${background === 'white' ? 'sectionTopSpacing': 'sectionSpacing'} ${bgColor} sectionBottomMargin`}>
            <div className="layout">
                <div className={`flex flex-col w-100 md:w-75 xl:w-60`}>
                    {breadcrumb && <Breadcrumbs content={breadcrumb} />}
                    {content?.headlineWithContent && <>
                        {content?.headlineWithContent[0]?.fields?.headline && <Headings variant="H1" content={content?.headlineWithContent[0]?.fields?.headline} classes="text-purple-100" />}
                        {content?.headlineWithContent[0]?.fields?.subheadline && <Headings variant="H3" content={content?.headlineWithContent[0]?.fields?.subheadline} classes="text-purple-100" />}
                        <Paragraph content={documentToHtmlString(content?.headlineWithContent[0]?.fields?.description || content?.headlineWithContent[0]?.fields?.richText)} />
                    </>}
                </div>
            </div>
        </div>
    </>)
}

export default PageBanner