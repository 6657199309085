import React, { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";

// Register Chart Component
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Charts = ({ type, data }) => {
  const [options, setOptions] = useState({});
  const colorSet = [
    "#159DA4",
    "#49247D",
    "#EE7B00",
    "#48CAD0",
    "#927CB1",
    "#182E57",
  ];
  useEffect(() => {
    if (type === "doughnut" || type === "pie") {
      setOptions({
        responsive: true,
        maintainAspectRatio: true,
        cutout: "70%",
        plugins: {
          legend: {
            position: "right",
            labels: {
              padding: 15,
              usePointStyle: true,
              // generateLabels: function (chart) {
                // console.log('chart.data.datasets', chart.data.datasets)
                // return ` ${chart.data.datasets[0].label} `;
              // },
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label || "";
                const value = context.raw;
                return ` ${value}%`;
              },
              labelPointStyle: function (context) {
                return {
                  pointStyle: "circle",
                };
              },
            },
          },
        },
      });
    } else {
      setOptions({
        responsive: true,
        plugins: {
          legend: { display: false },
          title: { display: false },
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label || "";
                const value = context.raw;
                return ` ${value}%`;
              },
              labelPointStyle: function (context) {
                return {
                  pointStyle: "circle",
                };
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              color: "#000",
              font: {
                size: 16,
              },
            },
          },
          y: {
            ticks: {
              color: "#000",
              font: {
                size: 16,
              },
              callback: function (value) {
                return value + "%";
              },
            },
          },
        },
      });
    }
  }, [type]);

  const mapChartData = (chart) => {
    if (!chart || !chart.data || !Array.isArray(chart.data)) {
      return {
        labels: [],
        datasets: [],
      };
    }

    return {
      labels: chart.labels,
      datasets: [
        {
          label: chart.chartName,
          data: chart.data.map((value) => parseFloat(value.replace("%", ""))),
          backgroundColor: type !== "bar" ? colorSet : colorSet[0],
          barThickness: 50,
        },
      ],
    };
  };

  if (mapChartData(data).datasets.length) {
    switch (type) {
      case "bar":
        return <Bar data={mapChartData(data)} options={options} />;
      case "line":
        return <Line data={mapChartData(data)} options={options} />;
      case "pie":
        return <Pie data={mapChartData(data)} options={options} />;
      case "doughnut":
        return <Doughnut data={mapChartData(data)} options={options} />;
      default:
        return <p>Invalid Charts</p>;
    }
  }
};

export default Charts;
