import * as React from "react"
import { ReactComponent as Spinner } from '../../../../../Assets/spinner.svg'
import { ReactComponent as ArrowForwardIcon } from '../../../../../Assets/arrowForward.svg'
import { ReactComponent as ArrowBackIcon } from '../../../../../Assets/arrowBack.svg'
import Image from "../../../Image"
import { buttonLinkClick } from "../../RenderButton"

interface Props {
    variant: string
    buttonType?: "button" | "submit" | "reset"
    link?: string
    arrowDirection?: string
    loading?: boolean
    disabled?: boolean
    classes?: string
    buttonIcon?: any
    target?: string
    componentName?: string
}

/**
 * Outline Button ONly Icon Component
 * @param variant - Variation of the button. Expected values: "light" | "dark"
 * @param buttonType (optional) - Type of the button. Expected values:  "button" | "submit" | "reset", Default: "button"
 * @param link (optional) - Page to be navigate on click of the button.
 * @param arrowDirection (optional) - Direction for the arrow. Expected values: "left" | "right"
 * @param loading (optional) - Loading of the button. Expected values:  true | false
 * @param disabled (optional) - Button to be disabled. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 * @param buttonIcon (optional) - Icon of the button.
 * @param target (optional) - Open url in new tab or not. Expected values: "_blank" | "_self". Default: "_self"
 * @param componentName (optional) - Name of the component from the contentful.
 */

const OutlineButtonOnlyIcon = ({ variant, buttonType = "button", link, arrowDirection = 'right', loading, disabled, classes, buttonIcon, target="_self", componentName }: Props) => {

  return (
    <>
      {variant === "light" && (<>
        <button aria-label="outline-button-only-icon-light" type={buttonType} className={`flex justify-center items-center text-base md:text-md rounded-lg 
            px-24 py-16
            ${loading ? 'bg-neutral-30 text-purple-100 border-white border pointer-events-none' : 'bg-transparent text-white border-neutral-50 border' }
            hover:bg-white hover:text-purple-100
            active:bg-neutral-30 active:text-purple-100 active:border-white active:border
            disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
            visited:bg-neutral-30 visited:text-purple-100 visited:border-white visited:border
            ${classes ? classes : ''}`}
            disabled={disabled}
            onClick={buttonType === 'button' ? (e: any) => buttonLinkClick(e, "", link, target, componentName) : undefined}
            data-testid="lightOutlineButtonOnlyIcon"
        >
          {!loading && <>
            {buttonIcon ? <Image imageUrl={buttonIcon?.fields?.file?.url} altText={buttonIcon?.fields?.file?.title} /> : arrowDirection === "left" ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </>}
          {loading && <Spinner className="animate-spin" />}
        </button>
        </>
      )}
      {variant === "dark" && (<>
        <button aria-label="outline-button-only-icon-dark" type={buttonType} className={`flex justify-center items-center text-base md:text-md rounded-lg 
            px-24 py-16
            ${loading ? 'bg-purple-hoverDark text-white border-purple-hoverDark border pointer-events-none' : 'bg-transparent text-purple-100 border-purple-100 border' }
            hover:bg-purple-100 hover:text-white hover:border-purple-100
            active:bg-purple-hoverDark active:text-white active:border-purple-100 active:border
            disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
            visited:bg-purple-hoverDark visited:text-white visited:border-purple-100 visited:border
            ${classes ? classes : ''}`}
            disabled={disabled}
            onClick={buttonType === 'button' ? (e: any) => buttonLinkClick(e, "", link, target, componentName) : undefined}
            data-testid="darkOutlineButtonOnlyIcon"
        >
          {!loading && <>
            {buttonIcon ? <Image imageUrl={buttonIcon?.fields?.file?.url} altText={buttonIcon?.fields?.file?.title} /> : arrowDirection === "left" ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </>}
          {loading && <Spinner className="animate-spin" />}
        </button>
        </>
      )}
    </>
  )
}

export default OutlineButtonOnlyIcon
