import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'

const webVitals = (vitals: any) => {
  console.log(`${vitals.name} - ${vitals.value}`)
  console.log(`${vitals.name} - ${vitals.value/100}`)
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)


reportWebVitals(webVitals)
