import * as React from "react"
import Paragraph from "../../Generic/Paragraph"
import Headings from "../../Generic/Heading"
import {ReactComponent as InfoTealIcon} from '../../../Assets/infoTeal.svg'
import {ReactComponent as InfoPurpleIcon} from '../../../Assets/infoPurple.svg'
import {ReactComponent as WarningIcon} from '../../../Assets/warning.svg'
import {ReactComponent as CloseIcon} from '../../../Assets/close.svg'
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"

interface Props {
    content?: any
    background?: string
    closeIcon?: boolean
}

/**
 * Banner Notification Component
 * @param content - Content from the CMS.
 * @param background - Background of the Alert. Expected values: "teal" | "purple" | "orange", Default: "teal"
 * @param closeIcon - Close Icon of the banner notification. Expected values: true | false, Default: false
 */
const BannerNotification = ({background = 'teal', content, closeIcon}: Props) => {
    background = background.toLowerCase()
    const bgColor = background === "purple" ? "bg-purple-100" : background === "orange" ? "bg-orange-10" : background === "teal" ? "bg-teal-10" : background === "lightpurple" ? "bg-purple-10" : "bg-white"
    const borderColor = background === "purple" ? "border-purple-100" : background === "orange" ? "border-orange-100" : background === "teal" ? "border-teal-100" : background === "lightpurple" ? "border-purple-100" : "border-none"

    const handleClose = (e) => {
        e.stopPropagation()
        e.target.closest('.bannerNotify').style.display = 'none'
    }
    
    return (<>
        <div aria-label={content?.fields?.title} className={`bannerNotify layoutSpacing ${bgColor}`}>
            <div data-testid="bannerNotification-teal" className={`w-100 layout`}>
                <div className={`flex justify-between items-center py-16 border-b rounded-2xl gap-16 md:gap-32 ${borderColor}`}>
                    <div className="flex justify-start gap-16 md:gap-32">
                        <div>
                            {background === 'orange' && <WarningIcon />}
                            {background === 'teal' && <InfoTealIcon />}
                            {background === 'purple' && <InfoPurpleIcon />}
                        </div>
                        <div>
                            <Headings variant="H4" content={content?.fields?.title} classes="text-black" />
                            <Paragraph content={documentToHtmlString(content?.fields?.message)} classes="!text-base md:!text-md" />
                        </div>
                    </div>
                    {closeIcon &&
                        <div className="self-start cursor-pointer" onClick={(e) => {handleClose(e)}}>
                            <CloseIcon />
                        </div>
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default BannerNotification