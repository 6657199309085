import * as React from "react";

interface Props {
  videoUrl: string;
}

/**
 * Video Component
 * @param videoUrl - URL of the video.
 */

const Video = ({ videoUrl }: Props) => {
  const extractVideoId = (url) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  return (
    <>
      <div className="w-full layout">
        <div data-testid="video" className={"relative w-full"}>
          <div className="video-responsive">
            <iframe
              width="560"
              height="315"
              src={`https://www.youtube.com/embed/${extractVideoId(videoUrl)}`}
              title="YouTube video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
