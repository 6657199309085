import React, { useEffect, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";

// Register Chart Component
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const Chart = (content) => {

    const colorSet = [
        "#159DA4",
        "#49247D",
        "#EE7B00",
        "#48CAD0",
        "#927CB1",
        "#182E57",
      ];

    const options = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        stacked: false,
        plugins: {
            legend: {
                labels: {
                    // This more specific font property overrides the global property
                    font: {
                        size: 16,
                        family: "'Manrope', sans-serif"
                    }
                }
            },
            title: {
                display: false,
                text: 'Daily Unit Prices',
                font: {
                    size: 16,
                    family: "'Manrope', sans-serif"
                }
            },
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    color: "#000",
                    font: {
                        size: 16,
                        family: "'Manrope', sans-serif"
                    },
                },
            },
            y: {
                grid: {
                    display: true,
                },
                ticks: {
                    color: "#000",
                    font: {
                        size: 16,
                        family: "'Manrope', sans-serif"
                    },
                },
            },
        },
    };
  
    const labels = content?.content[0]?.rowHeading;
    
    let dataSet = []
    content?.content[0]?.rowValues?.map((item, index) => {
        let val = []
        // if (item['date']) 
        Object.values(item).forEach((value) => {
            if (!isNaN(Number(value))) {
                val.push(value)
            }
        })
        const obj = {
            label: item['date'],
            data: val,
            borderColor: colorSet[index],
            backgroundColor: colorSet[index]
        }
        dataSet.push(obj)
    })
  
    const data = {
        labels,
        datasets: dataSet
    };

    return (
        <Line data={data} options={options} />
    )
}

export default Chart