import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as DropDownArrowUp } from "../../../../Assets/keyboard_arrow_up.svg";
import { ReactComponent as DropDownArrowDown } from "../../../../Assets/keyboard_arrow_down.svg";
import { ReactComponent as CheckPurple } from "../../../../Assets/checkPurple.svg";
import Selector from "../../Selector";

interface DropDownProps {
  label?: string;
  name?: string;
  className?: string;
  error?: boolean;
  errorMessage?: string;
  helpText?: string;
  isDisabled?: boolean;
  options?: Array<string>;
  withCheckBox?: boolean;
  required?: boolean;
  onSelect?: (selected: string | string[]) => void;
}

const DropdownComp = (props: DropDownProps) => {
  const {
    label,
    name,
    className,
    error,
    errorMessage,
    helpText,
    isDisabled = false,
    options,
    withCheckBox = false,
    required,
    onSelect,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    withCheckBox ? [] : ""
  );
  const dropdownRef = useRef(null);

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDisabled) {
      setIsOpen(!isOpen);
    }
    return;
  };

  const handleOptionChange = (option: string) => {
    let updatedSelection: string | string[];
    if (withCheckBox) {
      // toggle option selection for checkbox mode
      updatedSelection = Array.isArray(selectedOptions)
        ? selectedOptions.includes(option)
          ? selectedOptions.filter((item) => item !== option)
          : [...selectedOptions, option]
        : [];
      setSelectedOptions(updatedSelection);
    } else {
      // single selection for non-checkbox mode
      updatedSelection = option;
      setSelectedOptions(updatedSelection);
      setIsOpen(false);
    }

    onSelect(updatedSelection);
  };

  const getSelectedText = () => {
    if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
      const firstOption = selectedOptions[0];
      const remainingCount = selectedOptions.length - 1;
      return remainingCount > 0
        ? `${firstOption} +${remainingCount}`
        : firstOption;
    }
    return "Select Options";
  };

  const handelClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handelClickOutside);

    return () => {
      document.removeEventListener("mousedown", handelClickOutside);
    };
  });

  return (
    <div data-testid="dropdown" className={`${className}`}>
      <input
        type="hidden"
        name={name}
        value={selectedOptions}
        required={required}
      />
      {label && (
        <label className="block text-neutral-100 text-fontSize-md mb-3">
          {label}
        </label>
      )}

      <div ref={dropdownRef} className="relative inline-block text-left w-100">
        <button
          aria-label={`${selectedOptions ? selectedOptions : "Select an Option"}`}
          onClick={(e) => {
            toggleDropdown(e);
          }}
          className={`flex justify-between border border-neutral-50 rounded-md w-100 px-24 py-12 text-left text-neutral-100
                        mb-3 ${error ? "border-danger-100" : ""} 
                        ${
                          isDisabled
                            ? "border-neutral-50 bg-neutral-30 text-neutral-60 cursor-default"
                            : "hover:outline-none hover:border-purple-100 hover:text-neutral-100 active:outline-none active:border-purple-100active:text-neutral-100"
                        }`}
        >
          {withCheckBox
            ? getSelectedText()
            : selectedOptions || "Select an Option"}

          {isOpen ? <DropDownArrowUp /> : <DropDownArrowDown />}
        </button>

        {isOpen && (
          <div
            data-testid="dropdown-menu"
            className="absolute top-14 -mt-4 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
          >
            <div className="py-3">
              {options.map((option, index) => {
                return (
                  <>
                    {withCheckBox ? (
                      <Selector
                        className="w-100 item-center px-24 py-12 text-left cursor-pointer text-neutral-100 hover:bg-neutral-20 group"
                        type="checkbox"
                        label="Checkbox"
                        value={option}
                        checked={
                          Array.isArray(selectedOptions) &&
                          selectedOptions.includes(option)
                        }
                        onChange={(checked, value) => handleOptionChange(value)}
                      />
                    ) : (
                      <div
                        className="flex w-100 justify-between items-center px-24 py-12 text-left cursor-pointer text-neutral-100 hover:bg-neutral-20 group"
                        onClick={() => handleOptionChange(option)}
                      >
                        {option} {option === selectedOptions && <CheckPurple />}
                      </div>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {error && errorMessage && (
        <small className={`block text-danger-100 text-base mb-8`}>
          {errorMessage}
        </small>
      )}
      {helpText && (
        <small className="block text-neutral-100 text-base">{helpText}</small>
      )}
    </div>
  );
};

export default DropdownComp;
