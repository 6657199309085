import * as React from "react";

interface Props {
  children: React.ReactNode;
}

const ContainerSection = ({ children }: Props) => {
  return (
    <>
      <section data-testid="containerSection" className="w-100">
        {React.Children.map(children, (e: any) => (
          <React.Fragment key={e?.key}>{e}</React.Fragment>
        ))}
      </section>
    </>
  );
};

export default ContainerSection;
