const FormValidations = () => {

    let validations = []
    validations["emailSubject"] = "Event Registration"
    validations["success"] = "Your event registration has been submitted successfully"
    validations["emailAddress"] = {
        id: "emailAddress",
        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Invalid email address. Format: xyz@example.com"
    }
    validations["firstName"] = {
        id: "firstName",
        pattern: /^[a-zA-Z ]{2,100}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only alphabets and space, (min: 10, max: 100 characters allowed)"
    }
    validations["lastName"] = {
        id: "lastName",
        pattern: /^[a-zA-Z ]{2,100}$/,
        helpText: "",
        required: true,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only alphabets and space, (min: 10, max: 100 characters allowed)"
    }
    validations["mobileNumber"] = {
        id: "mobileNumber",
        pattern: /^\+([0-9]{1,2})?([0-9]{10})$/,
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers[0-9] and '+' (min: 10, max: 13 characters allowed) Format: +61123456789"
    }
    validations["dietaryRequirements"] = {
        id: "dietaryRequirements",
        pattern: "",
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["additionalRequirements"] = {
        id: "additionalRequirements",
        pattern: "",
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }
    validations["memberNumber"] = {
        id: "memberNumber",
        pattern: /^[0-9]{6,9}$/,
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: "Accepts only numbers(0-9),(min: 6, max: 9 characters allowed)"
    }
    validations["additionalGuests"] = {
        id: "additionalGuests",
        pattern: "",
        helpText: "",
        required: false,
        error: false,
        errorMsg: "",
        requiredMsg: "This field is mandatory",
        validationMsg: ""
    }

    return { validations }
    
}

export { FormValidations }