import * as React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import BaseTemplate from "./Components/Templates/BaseTemplate";
import { GAInitialize } from "./Components/GoogleAnalytics";
import { ContentfulLivePreviewProvider } from "@contentful/live-preview/react";

// const CustomApp = ({ Component, pageProps }) => (
//   <ContentfulLivePreviewProvider locale="en-US">
//     <Component {...pageProps} />
//   </ContentfulLivePreviewProvider>
// )

function App() {
  const RoutesConfig = () => {
    return useRoutes([
      {
        path: "*",
        element: <BaseTemplate data-testid="baseTemplate"/>,
      },
    ]);
  };

  return (
    <ContentfulLivePreviewProvider
      data-testid="contentfulLivePreviewProvider"
      locale="en-US"
      enableInspectorMode={true}
      enableLiveUpdates
      debugMode={true}
      targetOrigin="https://app.contentful.com"
    >
      <GAInitialize 
      data-testid="gaInitialize"/>
      <BrowserRouter
        data-testid="browserRouter"
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        <RoutesConfig />
      </BrowserRouter>
    </ContentfulLivePreviewProvider>
  );
}

export default App;
