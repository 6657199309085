import * as React from "react";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import TextButtonWithIcon from "../../Generic/Button/Text/ButtonWithIcon";
import Image from "../../Generic/Image";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

interface Props {
  content?: any;
}

/**
 * Listing Component
 * @param content - Content from the CMS.
 */
const Listing = ({ content }: Props) => {
  return (
    <>
      <div
      data-testid="listing"
        className={`flex flex-col lg:flex-row items-center bg-white border-b border-neutral-50 py-32 gap-40 last:border-0`}
      >
        {content?.imageUrl !== null && (
          <div className="flex justify-start lg:justify-center w-full lg:w-[200px]">
            <Image
              imageUrl={content?.imageUrl}
              altText="Listing Image"
              width="96"
            />
          </div>
        )}

        <div className="flex-1">
          <div className="">
            {/* <Paragraph
                      content="Tag"
                      classes="!text-base text-neutral-90 pb-16"
                    />
                    <Paragraph
                      content="18 Feb 2024"
                      classes="!text-base text-neutral-90 pb-24"
                    /> */}
            {content?.headLine !== null && (
              <Headings
                content={content?.headLine}
                variant="H4"
                classes="text-black !pb-16"
              />
            )}
            {content?.contentDescription !== null && (
              <Paragraph
                content={documentToHtmlString(content?.contentDescription)}
                classes="text-black pb-32"
              />
            )}
            {content?.contentCtaLinkToPage !== null &&
              content?.contentCtaLinkToPage.map((link: any, index) => {
                return (
                  <span key={`link-${index}`}>
                    <TextButtonWithIcon
                      name={link.fields.ctaText}
                      underline={true}
                      variant={
                        link.fields.ctaButtonVariety === "TextDark"
                          ? "dark"
                          : "light"
                      }
                      link={
                        link.fields.buttonLinkExternal &&
                        link.fields.buttonLinkExternal
                      }
                    />
                  </span>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
