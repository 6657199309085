import * as React from "react"
import { ReactComponent as Spinner } from '../../../../../Assets/spinner.svg'
import { ReactComponent as ArrowForwardIcon } from '../../../../../Assets/arrowForward.svg'
import Image from "../../../Image"
import { buttonLinkClick } from "../../RenderButton"

interface Props {
  name: string
  buttonType?: "button" | "submit" | "reset"
  link?: string
  loading?: boolean
  disabled?: boolean
  classes?: string
  buttonIcon?: any
  target?: string
  componentName?: string
}

/**
 * Teal Button With icon Component
 * @param name - Name of the button.
 * @param buttonType (optional) - Type of the button. Expected values:  "button" | "submit" | "reset", Default: "button"
 * @param link (optional) - Page to be navigate on click of the button.
 * @param loading (optional) - Loading of the button. Expected values:  true | false
 * @param disabled (optional) - Button to be disabled. Expected values:  true | false
 * @param classes (optional) - Tailwindcss classes to customize it.
 * @param buttonIcon (optional) - Icon of the button.
 * @param target (optional) - Open url in new tab or not. Expected values: "_blank" | "_self". Default: "_self"
 * @param componentName (optional) - Name of the component from the contentful.
 */

const TealButtonWithIcon = ({ name, buttonType = "button", link, loading, disabled, classes, buttonIcon, target="_self", componentName }: Props) => {

  return (
    <>
    <button aria-label={name} data-testid="tealButtonWithIcon" type={buttonType} className={`flex justify-center items-center text-base md:text-md text-black rounded-lg 
        px-24 py-12
        ${loading ? 'bg-teal-pressed pointer-events-none' : 'bg-teal-100 border-4 border-transparent' }
        hover:bg-teal-hover 
        focus:bg-teal-hover focus:border-4 focus:border-teal-100 focus:border-opacity-20 
        active:bg-teal-pressed 
        disabled:bg-neutral-30 disabled:border disabled:border-neutral-50 disabled:text-neutral-60 disabled:pointer-events-none
        visited:bg-teal-pressed
        ${classes ? classes : ''}`}
        disabled={disabled}
        onClick={buttonType === 'button' ? (e: any) => buttonLinkClick(e, name, link, target, componentName) : undefined}
    >
      {!loading && <>
        <span className="mr-12">{name}</span>
        {buttonIcon ? <Image imageUrl={buttonIcon?.fields?.file?.url} altText={buttonIcon?.fields?.file?.title} /> : <ArrowForwardIcon />}
      </>}
      {loading && <Spinner className="animate-spin" />}
    </button>
    </>
  )
}

export default TealButtonWithIcon
