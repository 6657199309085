import * as React from "react"
import { useEffect, useState } from "react"
import Paragraph from "../Paragraph"
import { ReactComponent as ArrowUpIcon } from '../../../Assets/arrowUp.svg'
import { ReactComponent as ArrowDownIcon } from '../../../Assets/arrowDown.svg'
import './style.css'
import { useDeviceDetector } from "../../../Hooks/useDeviceDetector"

interface Props {
    content?: any
    selectedTabs?: any
    setSelectedTabs?: any
    eventsData?: any
    setPageNumber?: any
}

/**
 * Tabs Selector Component
 * @param content - Content from the CMS.
 */
const TabSelector = ({content, selectedTabs, setSelectedTabs, eventsData, setPageNumber}: Props) => {
    const { isMobile } = useDeviceDetector()

    const [filterData, setFilterData] = useState([])

    const toggleTabs = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.tabs')
        const element = e.target.closest('.tabHeading').querySelectorAll('.tabs')
        for(var i=0; i<element.length; i++) {
            element[i].classList.remove('active')
        }
        parentEle.classList.add('active')
        const selected = {
            tabGroup: parentEle.getAttribute('data-group'),
            tabItem: parentEle.getAttribute('data-item')
        }
        const findIndex = selectedTabs?.findIndex((item) => item?.tabGroup === selected?.tabGroup)
        findIndex >= 0 && selectedTabs.splice(findIndex, 1)
        setSelectedTabs([...selectedTabs, selected])
        setPageNumber(1)
    }

    const toggleMobileTabs = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.mobileSelectedTab')
        parentEle.classList.toggle('active')
        parentEle.nextElementSibling.classList.toggle('active')
    }

    const selectTab = (e: any) => {
        e.stopPropagation()
        const parentEle = e.target.closest('.tabs')
        const tabSelector = e.target.closest('.mobileTab')
        const mobileSelectedTab = tabSelector.querySelector('.mobileSelectedTab')
        const text = parentEle.children[0].textContent
        mobileSelectedTab.querySelector('.selected p').innerHTML = text
        mobileSelectedTab.classList.toggle('active')
        mobileSelectedTab.nextElementSibling.classList.toggle('active')
        const selected = {
            tabGroup: parentEle.getAttribute('data-group'),
            tabItem: parentEle.getAttribute('data-item')
        }
        const findIndex = selectedTabs?.findIndex((item) => item?.tabGroup === selected?.tabGroup)
        findIndex >= 0 && selectedTabs.splice(findIndex, 1)
        setSelectedTabs([...selectedTabs, selected])
    }

    useEffect(() => {
        if (!eventsData || eventsData.length === 0 || !content || content.length === 0) return

        let memberOf = eventsData?.map((item) => item?.fields?.fundTypeValue)
        memberOf.push("All")
        memberOf = memberOf?.filter((item) => item !== undefined)
        memberOf = [...new Set(memberOf)].sort()
    
        let workingOrRetired = eventsData?.map((item) => item?.fields?.workingOrRetired)
        workingOrRetired.push("All")
        workingOrRetired = workingOrRetired?.filter((item) => item !== undefined)
        workingOrRetired = [...new Set(workingOrRetired)].sort()
    
        let eventLocation = eventsData?.map((item) => item?.fields?.eventLocation)
        eventLocation.push("All")
        eventLocation = eventLocation?.filter((item) => item !== undefined)
        eventLocation = [...new Set(eventLocation)].sort()

        let filters = []
        let selectedData = []

        content?.forEach((item) => {
            if (item?.fields?.headline === 'I am a member of...') {
                filters.push({
                    category: item?.fields?.headline,
                    subCategories: memberOf,
                    tabGroup: "fundTypeValue"
                })
                const items = {
                    tabGroup: "fundTypeValue",
                    tabItem: "All"
                }
                selectedData.push(items)
            }
            if (item?.fields?.headline === 'I am...') {
                filters.push({
                    category: item?.fields?.headline,
                    subCategories: workingOrRetired,
                    tabGroup: "workingOrRetired"
                })
                const items = {
                    tabGroup: "workingOrRetired",
                    tabItem: "All"
                }
                selectedData.push(items)
            }
            if (item?.fields?.headline === 'I’d like to attend..') {
                filters.push({
                    category: item?.fields?.headline,
                    subCategories: eventLocation,
                    tabGroup: "eventLocation"
                })
                const items = {
                    tabGroup: "eventLocation",
                    tabItem: "All"
                }
                selectedData.push(items)
            }
        })
        setFilterData(filters)
        selectedTabs.length === 0 && setSelectedTabs([...selectedTabs, ...selectedData])
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [eventsData, content])

    return (
        <div  className={`${content[0]?.fields?.carousel ? 'py-32' : 'border-b border-neutral-50 sectionBottomSpacing'}`}>
            <div data-testid="tabSelector" className="layoutSpacing">
                <div className={`flex flex-wrap gap-24 w-full layout tabSelector`}>
                    {filterData?.map((item, index) => <div key={`${item?.category}-${index}`} className={`${isMobile ? 'w-full' : 'min-w-[300px]'}`}>
                        {item?.subCategories &&
                            <div>
                                <Paragraph content={item?.category} classes="pb-8" />
                                {!isMobile ?
                                    <div className={`border border-neutral-50 rounded-lg overflow-hidden w-max`}>
                                        <div className={`tabHeading flex`}>
                                            {item?.subCategories?.map((tab, index) =>
                                                <div key={`${tab}-${index}`} data-group={item?.tabGroup} data-item={tab} className={`tabs px-24 py-16 cursor-pointer border-r border-neutral-50 overflow-hidden ${index === 0 ? 'border-r rounded-tl-lg rounded-bl-lg active' : index === item?.subCategories?.length - 1 ? 'border-none rounded-tr-lg rounded-br-lg' : ''}`} onClick={(e) => {toggleTabs(e)}}>
                                                    <Paragraph content={tab} />
                                                </div>   
                                            )}
                                        </div>
                                    </div>
                                :
                                    <div className={`relative mobileTab`}>
                                        <div className={`mobileSelectedTab flex justify-between items-center p-16 border border-neutral-50 rounded-lg overflow-hidden`} onClick={(e) => {toggleMobileTabs(e)}}>
                                            <div className={`selected flex justify-start items-center gap-12 cursor-pointer`}>
                                                <Paragraph content={item?.subCategories[0]} />
                                            </div>
                                            <div className="">
                                                <ArrowUpIcon />
                                                <ArrowDownIcon />
                                            </div>
                                        </div>
                                        
                                        <div className={`mobileTabHeading flex flex-col border border-neutral-50 rounded-bl-lg rounded-br-lg absolute top-full left-0 bg-white w-full z-10 overflow-hidden shadow-xl`}>
                                            {item?.subCategories?.map((tab, index) =>
                                                <div key={`${tab}-${index}`} data-group={item?.tabGroup} data-item={tab} className={`tabs p-16 border border-t-0 border-neutral-50 cursor-pointer`} onClick={(e) => {selectTab(e)}}>
                                                    <Paragraph content={tab} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default TabSelector