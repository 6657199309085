import { useEffect, useState } from "react"
import axios from 'axios'
import * as jose from 'jose'

/**
 * Content API to get based on entries and content type
 * @param apiType - Type of the API. Expected Values : 'entries' | 'contentType'.
 * @param contentTypeId (Optional) - Content Type Id of the content.
 * @param slug (Optional) - Slug of the page.
 * @param preview (Optional) - Enables Live preview. Expected Values : 'true' | 'false'.
 */

const API_BASE_URL = process.env.REACT_APP_PWS_API_URL

const generateJWT = async (payload) => {
    const JWTSecret = new TextEncoder().encode(
        process.env.REACT_APP_JWT_SECRET,
    )
    const alg = 'HS256'

    const jwt = await new jose.SignJWT(payload)
    .setProtectedHeader({ alg })
    .setIssuedAt()
    .setIssuer(process.env.REACT_APP_JWT_ISSUER)
    .setAudience(process.env.REACT_APP_JWT_AUDIENCE)
    .setExpirationTime(process.env.REACT_APP_JWT_EXPIRATION_TIME)
    .sign(JWTSecret)

    return jwt
}

const useContentAPI = (apiType: string, contentTypeId: string, slug: string, preview: boolean) => {
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState(null)

    const entriesAPI = async () => {
        try {
            const payload = { 'pws': true }
            const jwtToken = await generateJWT(payload)

            const options = {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            }

            let response = await axios.get(`${API_BASE_URL}/api/entries/${slug}${preview ? '?preview=true' : ''}`, {...options})
            setData(response)
        } catch (err) {
            setError(err)
        }
        setIsLoading(false)
    }

    const contentTypeAPI = async () => {
        try {
            const payload = { 'pws': true }
            const jwtToken = await generateJWT(payload)

            const options = {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            }

            let response = await axios.get(`${API_BASE_URL}/api/entries/contentType/${contentTypeId}${preview ? '?preview=true' : ''}`, {...options})
            setData(response)
        } catch (err) {
            setError(err)
        }
        setIsLoading(false)
    }

    const contentModelAPI = async () => {
        try {
            const payload = { 'pws': true }
            const jwtToken = await generateJWT(payload)

            const options = {
                headers: {
                    Authorization: `Bearer ${jwtToken}`
                }
            }

            let response = await axios.get(`${API_BASE_URL}/api/contentModel/${contentTypeId}${preview ? '?preview=true' : ''}`, {...options})
            setData(response)
        } catch (err) {
            setError(err)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if(apiType === 'entries') {
            entriesAPI()
        } else if(apiType === 'contentType') {
            contentTypeAPI()
        } if(apiType === 'contentModel') {
            contentModelAPI()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { data, isLoading, error }
}

const UseEmailAPI = async (payload: any) => {

    try {
        const jwtToken = await generateJWT(payload)

        const options = {
            headers: {
                Authorization: `Bearer ${jwtToken}`
            }
        }
        let response = await axios.get(`${API_BASE_URL}/api/formSubmission`, {...options})

        return response
    } catch (err) {
        return err
    }
}

const UseDailyUnitPriceAPI = async () => {

    try {
        const jwtToken = await generateJWT({})

        const options = {
            headers: {
                Authorization: `Bearer ${jwtToken}`
            }
        }
        let response = await axios.get(`${API_BASE_URL}/api/dailyUnitPrice`, {...options})

        return response
    } catch (err) {
        return err
    }
}

const UseVerifyReCaptchaAPI = async (payload) => {

    try {
        const jwtToken = await generateJWT(payload)

        const options = {
            headers: {
                Authorization: `Bearer ${jwtToken}`
            }
        }
        let response = await axios.get(`${API_BASE_URL}/api/verify/reCaptcha`, {...options})

        return response
    } catch (err) {
        return err
    }
}

export { useContentAPI, UseEmailAPI, UseDailyUnitPriceAPI, UseVerifyReCaptchaAPI }