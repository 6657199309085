import * as React from "react";
import { useEffect, useState } from "react";
import TabSelector from "../../Generic/TabSelector";
import EventCards from "./Cards";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { useContentAPI } from "../../../Hooks/useAPI";
import EventDetails from "./Detail";
import resolveResponse from "contentful-resolve-response";

interface Props {
  content?: any;
  background?: string;
  cardBackground?: string;
  columnLayout?: string;
  border?: boolean;
}

/**
 * Event Cards Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background of the Card Container. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param cardBackground (optional) - Background of the Card. Expected values: "white" | "teal" | "purple" | "orange", Default: "white"
 * @param columnLayout (optional) - No. of Columns Layout. Expected values: "2" | "3" | "4" | "orange", Default: "white"
 * @param border (optional) - Outline/Border of the Card. Expected values: true | false, Default: false
 */

const Events = ({
  content,
  background = "white",
  cardBackground = "white",
  columnLayout = "2",
  border = false,
}: Props) => {
  const [selectedTabs, setSelectedTabs] = useState([]);
  const [eventDetailsData, setEventDetailsData] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [contentData, setContentData] = useState([])
  const { data } = useContentAPI("contentType", "eventsBlockComponent", "", false)
  const bgColor =
    background === "purple"
      ? "bg-purple-10"
      : background === "orange"
      ? "bg-orange-10"
      : background === "teal"
      ? "bg-teal-10"
      : "bg-white";

  useEffect(() => {
    if (!data || data === null) return

    const resolver = {
      items: data?.data?.items,
      includes: data?.data?.includes,
    };
    const response = resolveResponse(resolver);
    setContentData(response)

    if (window.location.search.includes('eventId')) {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const evtId = Number(urlParams?.get('eventId'))
      const details = response?.filter((item) => item?.fields?.eventId === evtId)
      setEventDetailsData(details)
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, window.location.search])

  return (
    <>
      <div data-testid="event" className={`${bgColor}`}>
        {(content?.headline || content?.description) && (
          <div className="layoutSpacing">
            <div className="w-full layout">
              <div className="w-full md:w-3/4">
                {content?.headline && (
                  <Headings
                    content={content?.headline}
                    variant="H2"
                    classes="text-purple-100"
                  />
                )}
                {content?.description && (
                  <Paragraph
                    content={documentToHtmlString(content?.description)}
                    classes="pb-16"
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {eventDetailsData.length === 0 ? 
          <>
          <TabSelector
            content={content?.features}
            selectedTabs={selectedTabs}
            setSelectedTabs={setSelectedTabs}
            eventsData={contentData}
            setPageNumber={setPageNumber}
            data-testid="tabSelector"
          />

          <EventCards
            border={true}
            content={content?.features}
            eventsData={contentData}
            selectedTabs={selectedTabs}
            carousel={content?.features[0]?.fields?.carousel}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            data-testid="eventCardss"
          />
        </>
        : 
          <EventDetails content={content?.features} eventDetailsData={eventDetailsData} eventsData={contentData} data-testid="eventDetails"/>
        }
      </div>
    </>
  );
};

export default Events;
