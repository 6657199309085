import * as React from "react";
import PrimaryButtonWithIcon from "./Primary/ButtonWithIcon";
import SecondaryButtonWithIcon from "./Secondary/ButtonWithIcon";
import OutlineButtonWithIcon from "./Outline/ButtonWithIcon";
import TextButtonWithIcon from "./Text/ButtonWithIcon";
import TealButtonWithIcon from "./Teal/ButtonWithIcon";
import DownloadButtonWithIcon from "./Download/ButtonWithIcon";
import CardButton from "./Card/Button";
import { useEffect, useState } from "react";
import { TrackGAEvent } from "../../GoogleAnalytics";

interface Props {
  item: any;
  columns?: string;
}

/**
 * Render Button Component
 * @param item - Objects of the button.
 */

const RenderButton = ({ item }: Props) => {
  let buttonType = item?.fields?.ctaButtonVariety;
  buttonType = buttonType && buttonType.toLowerCase();
  const buttonIcon = item?.fields?.besideButtonIcon
  const componentName = item?.fields?.componentName

  return (
    <>
      {buttonType === "primarylight" && (
        <div key={item?.sys?.id}>
          <PrimaryButtonWithIcon
            name={item?.fields?.ctaText}
            variant="light"
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            componentName={componentName}
          />
        </div>
      )}
      {buttonType === "primarydark" && (
        <div key={item?.sys?.id}>
          <PrimaryButtonWithIcon
            name={item?.fields?.ctaText}
            variant="dark"
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            componentName={componentName}
          />
        </div>
      )}
      {buttonType === "secondary" && (
        <div key={item?.sys?.id}>
          <SecondaryButtonWithIcon
            name={item?.fields?.ctaText}
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            componentName={componentName}
          />
        </div>
      )}
      {buttonType === "outlinelight" && (
        <div key={item?.sys?.id}>
          <OutlineButtonWithIcon
            name={item?.fields?.ctaText}
            variant="light"
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            componentName={componentName}
          />
        </div>
      )}
      {buttonType === "outlinedark" && (
        <div key={item?.sys?.id}>
          <OutlineButtonWithIcon
            name={item?.fields?.ctaText}
            variant="dark"
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            componentName={componentName}
          />
        </div>
      )}
      {buttonType === "textlight" && (
        <div key={item?.sys?.id}>
          <TextButtonWithIcon
            name={item?.fields?.ctaText}
            variant="light"
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            underline={true}
            componentName={componentName}
          />
        </div>
      )}
      {buttonType === "textdark" && (
        <div key={item?.sys?.id}>
          <TextButtonWithIcon
            name={item?.fields?.ctaText}
            variant="dark"
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            underline={true}
            componentName={componentName}
          />
        </div>
      )}
      {buttonType === "teal" && (
        <div key={item?.sys?.id}>
          <TealButtonWithIcon
            name={item?.fields?.ctaText}
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            componentName={componentName}
          />
        </div>
      )}
      {buttonType === "downloadbutton" && (
        <div key={item?.sys?.id}>
          <DownloadButtonWithIcon
            variant="dark"
            name={item?.fields?.ctaText}
            link={item?.fields?.buttonLinkExternal}
            classes=""
            buttonIcon={buttonIcon}
            componentName={componentName}
          />
        </div>
      )}
      {(buttonType === 'cardbutton-stacked' || buttonType === 'cardbutton-horizontal') &&
        <div key={item?.sys?.id}>
          <CardButton
            variant={item?.fields?.ctaButtonVariety?.toLowerCase() === 'cardbutton-stacked' ? 'stacked' : ''}
            name={item?.fields?.ctaText}
            link={item?.fields?.buttonLinkExternal}
            iconUrl={item?.fields?.besideButtonIcon.fields.file.url}
            altText={item?.fields?.besideButtonIcon.fields.file.fileName}
            classes=""
            componentName={componentName}
          />
        </div>
      }
    </>
  );
};

const RenderCardButton = ({ item, columns }: Props) => {
  return (
    <div className="flex flex-wrap gap-16">
    {item?.map((button) => (
      <div
        key={button?.sys?.id}
        className={`w-full md:w-[48.5%] ${columns} md:max-w-[50%]`}
      >
        <CardButton
          variant={button?.fields?.ctaButtonVariety?.toLowerCase() === 'cardbutton-stacked' ? 'stacked' : ''}
          name={button?.fields?.ctaText}
          link={button?.fields?.buttonLinkExternal}
          iconUrl={button?.fields?.besideButtonIcon.fields.file.url}
          altText={button?.fields?.besideButtonIcon.fields.file.fileName}
          classes=""
        />
      </div>
    ))}
  </div>
  );
};


const ButtonWrapper = (content: any, columns) => {
  const [flexDirection, setFlexDirection] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    if(!content) return
    let filterBtn = []
    if (content?.content?.features) {
      setFlexDirection(content?.content?.features?.filter((item) => item?.fields?.ctaButtonVariety?.toLowerCase().includes('cardbutton')).length === 0)
      filterBtn = content?.content?.features?.filter((btn) => btn?.sys?.contentType?.sys?.id === 'ctaButtonComponent')
    } else if (content?.content?.button) {
      setFlexDirection(content?.content?.button?.filter((item) => item?.fields?.ctaButtonVariety?.toLowerCase().includes('cardbutton')).length === 0)
      filterBtn = content?.content?.button?.filter((btn) => btn?.sys?.contentType?.sys?.id === 'ctaButtonComponent')
    }
    setData(filterBtn)
  }, [content])

  return (
    <div className={`flex ${flexDirection ? 'flex-col' : 'flex-wrap'} gap-24`}>
      {data?.map((item, index) => (
        <div key={`${item?.fields?.ctaButtonVariety}-${index}`} data-testid="ctaTestBtn" className={`${(item?.fields?.ctaButtonVariety?.toLowerCase().includes('cardbutton')) ? `w-full ${columns} sm:w-[calc(50%-12px)] lg:w-[calc(33.33%-16px)]` : ''}`}>
          <RenderButton item={item} />
        </div>
      ))}
    </div>
  )
}

const buttonLinkClick = (e: React.MouseEvent<HTMLButtonElement>, name: string, link: string, target: string, componentName?: string, onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void ) => {
  e.preventDefault()
  e.stopPropagation()

  TrackGAEvent('button_click', name, link, componentName)

  if (link?.includes('scroll#')) {
    const id = link.replace('scroll#', '')
    const targetElement = document.getElementById(id)
    targetElement?.classList.remove('hidden')
    const top = targetElement?.offsetTop
    window.scrollTo({top: top, behavior: 'smooth'})
  } else {
    if (target === "_blank") {
      window.open(link, target)
    } else {
      window.location.href = link
    }
  }
  if(onClick) {
    onClick(e)
  }
}

export { RenderCardButton, RenderButton, ButtonWrapper, buttonLinkClick };
