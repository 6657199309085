import React, { useEffect, useState } from "react";
import Charts from "../Charts";
import Headings from "../../Generic/Heading";
import Paragraph from "../../Generic/Paragraph";

const ChartsContainer = ({ content }) => {
  const [monthlyReturns, setMonthlyReturns] = useState({});
  const [yearWiseChartData, setYearWiseChartData] = useState([]);
  const [chartType, setChartType] = useState("bar");

  const getMonthlyReturnsChartData = (tableData) => {
    return {
      chartName: tableData[0][0][0],
      labels: tableData[0].slice(1).map((item) => item[0]),
      data: tableData[1].slice(1).map((item) => item[0]),
    };
  };

  const getYearWiseChartData = (tableData) => {
    const labelsRow = tableData
      .filter((row) => /^[0-9]+yr$/.test(row[0]))
      .map((row) => row[0]);
    const yearWiseChartData = tableData[0]
      .slice(1) // Skip the 1st columns
      .map((columnName, columnIndex) => {
        return {
          chartName: columnName[0],
          labels: labelsRow.map((elm) => elm[0]),
          data: tableData
            .filter((row) => labelsRow.includes(row[0]))
            .map((row) => row[columnIndex + 1][0]),
        };
      });

    return yearWiseChartData;
  };

  const extractTableDataFromRichtext = (richtextData) => {
    const tableNodes = richtextData.nodeType === "table";
    if (!tableNodes) return [];
    const tableData = richtextData.content.map((tableRows) => {
      return tableRows.content.map((row) => {
        return row.content.map((cell) => {
          if (
            cell.nodeType === "paragraph" &&
            cell.content[0].nodeType === "text"
          ) {
            return cell.content[0].value;
          } else {
            return "";
          }
        });
      });
    });

    setMonthlyReturns(getMonthlyReturnsChartData(tableData));
    setYearWiseChartData(getYearWiseChartData(tableData));

    return tableData;
  };

  useEffect(() => {
    if (!content.referenceName) {
      return;
    } else {
      setChartType(content.chartType);
      content.referenceName.map((richTextData) => {
        if (richTextData.fields.chartDetails) {
          richTextData.fields.chartDetails.content.map((richText) => {
            extractTableDataFromRichtext(richText);
          });
        }
      });
    }
  }, content.referenceName);

  return (
    <div className="">
      <div className="p-4 py-48 border-b border-neutral-50">
        <div className="layout mb-24">
          <Headings variant="h4" content="Accumulation Plan" />
          <Paragraph
            content="Financial Year to Date (FYTD) Performance"
            classes="mb-24"
          />
          {Object.keys(monthlyReturns) && (
            <Charts type={chartType} data={monthlyReturns} />
          )}
        </div>
        <div className="layout flex flex-wrap gap-48">
          {yearWiseChartData.length &&
            yearWiseChartData.map((item, index) => {
              // Not mapping the column which doesn't have data.
              if (item.data.every((el) => el === "")) {
                return;
              }
              return (
                <div
                  className="w-full sm:w-[calc(50%-24px)] lg:w-[calc(33.33%-32px)] mb-24"
                  key={`chart-${index}`}
                >
                  <Headings
                    variant="h4"
                    content={item.chartName}
                    classes={`${!item.description && "mb-16"}`}
                  />
                  {item.description && (
                    <Paragraph content={item.description} classes="mb-24" />
                  )}
                  {Object.keys(monthlyReturns) && (
                    <Charts type={chartType} data={item} />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ChartsContainer;
