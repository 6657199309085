import * as React from "react"
import TextButton from "../../Generic/Button/Text/Button"
import Paragraph from "../../Generic/Paragraph"
import { useEffect, useState } from "react"

interface Props {
    content?: any
}

/**
 * Breadcrumbs Component
 * @param content - Content from the CMS.
 */
const Breadcrumbs = ({content}: Props) => {
    const [data, setData] = useState([])
    const [load, setLoad] = useState(false)
    let array = []

    const iterateObject = (obj) => {
        const breadcrumbData = {title: obj['pageTitle'], slug: obj['slug'], link: obj['externelLink']}
        const filteredArray = array.filter((item) => item.title === obj['pageTitle'] && item.slug === obj['slug'])
        filteredArray.length === 0 && array.push(breadcrumbData)
        if (obj['linkToParent']?.fields) {
            iterateObject(obj['linkToParent']?.fields)
        } else {
            setLoad(true)
        }
    }

    useEffect(() => {
        if (!load) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            array = []
            if (!content['linkToParent']?.fields && array.length === 0) {
                const breadcrumbData = {title: 'Home', slug: 'home', link: '/'}
                array.push(breadcrumbData)
            } else {
                iterateObject(content)
            }
            const finalData = array.reverse()
            setData(finalData)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [load])

    return (<>
        <div data-testid="breadcrumbs" className={`w-100 flex flex-wrap items-center gap-8 mb-32`}>
            {load && data?.map((item, index) => 
                <div className="flex gap-12" key={`${item?.title}-${index}`}>
                    {(data.length === 1 || index !== data.length - 1) ?
                        <div className="flex gap-12" key={`${item?.title}-${index}`}>
                            <div>
                                <TextButton variant="dark" name={item?.title} link={`${item?.link ? item?.link : `/${item?.slug}`}`} fontColor="text-black" underline={true} classes="!font-normal w-max" />
                            </div>
                            <div>
                                <Paragraph content="/" />
                            </div>
                        </div>
                        :
                        <div>
                            <Paragraph content={item?.title} classes=" w-max" />
                        </div>
                    }
                </div>
            )}
        </div>
        </>
    )
}

export default Breadcrumbs