import * as React from "react"
import Headings from "../../Generic/Heading"
import Paragraph from "../../Generic/Paragraph"
import { documentToHtmlString } from "@contentful/rich-text-html-renderer"
import './style.css'
import { ButtonWrapper } from "../../Generic/Button/RenderButton"
import PageNotFound from "../PageNotFound"
import Video from "../../Generic/Video"
import Image from "../../Generic/Image"
interface Props {
    content?: any
    background?: string
    marginBottom?: boolean
}

/**
 * TextBlock Component
 * @param content - Content from the CMS.
 * @param background (optional) - Background color of the component. Expected values: "white" | "purple" | "orange" | "lightPurple" , Default: "white"
 */
const TextBlock = ({content, background = 'white', marginBottom = true}: Props) => {
    background = background.toLowerCase()
    const columns = content?.cardLayout === '3' ? 'xl:w-[32%]' : content?.cardLayout === '4' ? 'xl:w-[23%]' : 'xl:w-[49%]'
    const bgColor = background === "purple" ? "bg-purple-100" : background === "orange" ? "bg-orange-10" : background === "teal" ? "bg-teal-10" : background === "lightpurple" ? "bg-purple-10" : "bg-white"
    return (<>
        {content?.componentName !== "404 page not found" ? 
            <div className="textBlock">
                <div data-testid="textBlock" className={`layoutSpacing ${bgColor}`}>
                    <div className={`w-full ${bgColor !== 'bg-white' ? 'sectionSpacing' : 'sectionBottomSpacing'} 
                    ${bgColor !== 'bg-white' && marginBottom ? 'sectionBottomMargin' : '' } layout`}>
                        <div id={content?.headline} className="layout">
                            <div  className={`flex ${content?.texColtLayout ? 'flex-col xl:flex-row gap-24' : 'flex-col w-100 md:w-75 xl:w-60'}`}>
                                {content?.headline && <Headings variant="H2" content={content?.headline} classes="text-purple-100 !pb-24" />}
                                {content?.subheadline && <Headings variant="H3" content={content?.subheadline} classes="text-purple-100" />}
                                <Paragraph content={documentToHtmlString(content?.contentDescription || content?.bodyText  || content?.description || content?.richText)} classes="pb-24" />
                            </div>
                            <ButtonWrapper content={content} columns={columns} />
                        </div>
                        <div className="w-100 md:w-75 xl:w-60">
                            {content?.assets?.map((item, index) => (<div key={`${item?.fields?.title}-${index}`}>
                                {item?.fields?.file?.contentType.includes('image') && 
                                    <Image imageUrl={item?.fields?.file?.url} altText={item?.fields?.description} />
                                }
                                {item?.fields?.file?.contentType.includes('video') && 
                                    <Video videoUrl={item?.fields?.file?.url} />
                                }
                                </div>
                            ))}
                            {content?.video && 
                                <Video videoUrl={content?.video} />
                            }
                        </div>
                    </div>
                </div>
            </div>
        :
            <PageNotFound content={content} />
        }
    </>)
}

export default TextBlock